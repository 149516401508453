import React from 'react';
// a line placeholder to keep the line number is same as other language file

export const VI = {
	$$settings: {
		code: 'vi',
		name: 'Tiếng Anh',
		fallback: ''
	},
	// fix key, anything under PLAIN will not be proxy to other type
	// anything must be string, should be placed here.
	// such as input tooltip(string property), tuple name(not for react component)
	PLAIN: {
		CONSOLE_SEARCH_PLACEHOLDER: 'Tìm kiếm không gian kết nối, đối tượng, báo cáo và bảng điều khiển',
		NEW_DASHBOARD_NAME: 'Bảng điều khiển',
		DEFAULT_DASHBOARD_NAME: 'Bảng điều khiển',
		NEW_CONNECTED_SPACE_NAME: 'Không gian kết nối',
		DEFAULT_CONNECTED_SPACE_NAME: 'Không gian kết nối',
		NEW_SUBJECT_NAME: 'Đối tượng',
		DEFAULT_SUBJECT_NAME: 'Đối tượng',
		NEW_REPORT_NAME: 'Báo cáo',
		DEFAULT_REPORT_NAME: 'Báo cáo',
		CONSTANT_INPUT_PLACEHOLDER: 'Nhâp Giá trị không đổi tại đây...',
		UNKNOWN_TOPIC_NAME: 'Chủ đề không xác định',
		UNKNOWN_FACTOR_NAME: 'Yếu tố không xác định',
		REPORT_DESCRIPTION_PLACEHOLDER: 'Nhập mô tả tại đây',
		LOADING: 'Loading...',
		FIND_INDICATOR_PLACEHOLDER: 'Tìm bởi chỉ báo, mô tả',
		FIND_TOPIC_OR_FACTOR_OR_SUBJECT_PLACEHOLDER: 'Tìm bởi tên chủ đề, yếu tố, đối tượng',
		INDICATOR_NAME_PLACEHOLDER: 'Chỉ báo tên người',
		INDICATOR_DESCRIPTION_PLACEHOLDER: 'Mô tả chỉ báo tại đây...',
		FIND_BUCKET_PLACEHOLDER: 'Tìm bởi tên bucket, mô tả',
		BUCKET_CATEGORY_SEGMENT_VALUE_PLACEHOLDER: 'Nhập giá trị danh mục tại đây, xác nhận để thêm vào phần này.',
		FIND_INDICATOR_VALUE_BUCKETS_PLACEHOLDER: 'Tìm kiếm bởi tên bucket.',
		FIND_OBJECTIVE_PLACEHOLDER: 'Tìm kiếm bởi tên đối tượng, mô tả',
		OBJECTIVE_NAME_PLACEHOLDER: 'Đối tượng tên người',
		OBJECTIVE_DESCRIPTION_PLACEHOLDER: 'Mô tả đối tượng tại đây...',
		OBJECTIVE_VARIABLE_NAME_PLACEHOLDER: 'Tên biến.',
		OBJECTIVE_FACTOR_NAME_PLACEHOLDER: 'Tên chỉ báo',
		OBJECTIVE_FORMULA_CONSTANT_PLACEHOLDER: 'Sử dụng "{}" để bao gồm biến.',
		OBJECTIVE_FACTOR_FILTER_CONSTANT_PLACEHOLDER: 'Sử dụng "{}" để  bao gồm biến.',
		SUBSCRIBE_MAIL_ADDRESS_PLACEHOLDER: '";" để phân cách nhiều địa chỉ.'
	},
	STANDARD: {
		YES: 'Có',
		NO: 'Không'
	},
	ERROR: {
		UNAUTHORIZED: 'Không có quyền truy cập, vui lòng đăng nhập',
		ACCESS_DENIED: 'Từ chối truy cập, vui lòng liên hệ Admin để biết thêm chi tiết.',
		UNPREDICTED: 'Có lỗi xảy ra, vui lòng liên hệ Admin để biết thêm chi tiết.'
	},
	ALERT: {
		BUTTON: 'Đã hiểu',
		NOT_IMPLEMENT: 'Chưa được thực hiện.'
	},
	DIALOG: {
		BUTTON_YES: 'Có',
		BUTTON_NO: 'Không'
	},
	ACTIONS: {
		SAVE: 'Lưu',
		COPY: 'Sao lưu',
		CLOSE: 'Đóng',
		CANCEL: 'Huỷ',
		DELETE: 'Xoá',
		REMOVE: 'Loại bỏ',
		CONFIRM: 'Xác nhận',
		NEXT: 'Tiếp',
		SORT_ASC: 'Thứ tự tăng lên',
		SORT_DESC: 'Thứ tự giảm dần',
		PREVIOUS_PAGE: 'Trang trước',
		NEXT_PAGE: 'Trang sau',
		EXPAND: 'Mở rộng',
		COLLAPSE: 'Ghép',
		MINIMIZE: 'Thu nhỏ',
		MAXIMIZE: 'Phóng to',
		RESTORE: 'Lưu trữ'
	},
	LOGIN: {
		PRODUCT_TITLE: <>Indexical Metrics <span>&</span> Measure Advisory</>,
		MORNING: 'Chào buổi sáng !',
		AFTERNOON: 'Chào buổi chiều!',
		EVENING: 'Chào buổi tối !',
		BUTTON: 'Bắt đầu !',
		NAME_EMPTY: 'Hãy nói cho tôi biết bạn là ai.',
		CREDENTIAL_EMPTY: 'Bạn cần được xác thực trước khi bắt đầu.',
		FAIL: 'Không xác thực được.'
	},
	ADMIN: {
		UNKNOWN_USER_GROUP: 'Nhóm người dùng không xác định',
		NONAME_USER_GROUP: 'Nhóm người dùng không tên'
	},
	DATASET: {
		UNFIX_COLUMN: 'Bỏ cố định và tiếp tục',
		FIX_COLUMN: 'Cố định cột tại đây',
		COMPRESS_COLUMNS: 'Nén cột',
		DOWNLOAD_PAGE: 'Xoá trang này',
		DOWNLOAD_ALL: 'Xoá tất cả',
		SIMULATE_DATA: 'Mô phỏng',
		UPLOAD_DATA: 'Tải số liệu',
		UPLOAD_DATA_FAILURE: 'Nhập tệp số liệu thất bại, kiểm tra lại định dạng của tệp.',
		DOWNLOAD_TEMPLATE: 'Tải mẫu'
	},
	CONSOLE: {
		LOADING: 'Đang tải số liệu cá nhân...',
		BYE: 'Dừng tại đây?',
		ERROR: {
			DASHBOARD_NOT_FOUND: 'Không tìm thấy bảng điều khiển này.',
			CONNECTED_SPACE_NOT_FOUND: 'Không tìm thấy không gian kết nối này.',
			SUBJECT_NOT_FOUND: 'Không tìm thấy đối tượng này.',
			REPORT_NOT_FOUND: 'Không tìm thấy báo cáo này.'
		},
		MENU: {
			TITLE: 'Watchmen Console',
			HOME: 'Trang chủ',
			DASHBOARDS: 'Bảng điều khiển',
			FAVORITE: 'Hiện mục yêu thích',
			CONNECT_SPACE: 'Không gian kết nối',
			SETTINGS: 'Cài đặt',
			TO_CONSOLE: 'Điều khiển',
			TO_ADMIN: 'Quản trị',
			TO_DATA_QUALITY: 'Tới trung tâm chất lượng số liệu',
			TO_INDICATOR: 'Tới chỉ báo',
			LOGOUT: 'Đăng xuất'
		},
		FAVORITE: {
			TITLE: 'Mục yêu thích của tôi',
			NO_DATA: 'Không có mục yêu thích',
			PIN: 'Ghim',
			UNPIN: 'Bỏ ghim'
		},
		HOME: {
			TITLE: 'Trang chủ',
			SORT_BY_NAME: 'Phân loại Theo tên',
			SORT_BY_VISIT_TIME: 'Phân loại Theo thời gian ghé trang',
			VIEW_COLLAPSE: 'Ghép',
			VIEW_ALL: 'Xem tất cả',
			CONNECTED_SPACE_TITLE: 'Không gian truy cập gần đây',
			CREATE_CONNECTED_SPACE_BUTTON: 'Kết nối không gian',
			DASHBOARD_TITLE: 'Bảng điều khiển gần đây',
			CREATE_DASHBOARD_BUTTON: 'Tạo bảng điều khiển',
			NO_RECENT: 'Không sử dụng gần đây.'
		},
		DASHBOARD: {
			SHARE: 'Chia sẻ',
			PRINT: 'In',
			REFRESH: 'Tải lại',
			AUTO_REFRESH: 'Tải lại tự động (5 Phút)',
			ADD_INTO_FAVORITE: 'Thêm vào mục yêu thích',
			REMOVE_FROM_FAVORITE: 'Bỏ khỏi mục kiêu thích',
			ADD_REPORT: 'Thêm báo cáo',
			ADD_PARAGRAPH: 'Thêm đoạn',
			ADD_DASHBOARD: 'Thêm bảng điều khiển',
			SWITCH_DASHBOARD: 'Đổi bảng điều khiển',
			DELETE_ME: 'Xoá',
			SHARE_DIALOG_LABEL: 'Sao chép liên kết này, và chia sẻ tới nơi bạn muốn chia sẻ',
			URL_COPIED: 'Đã sao chép liên kết.',
			DELETE_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá bảng điều khiển này? Xin lưu ý bạn không thể khôi phục lại sau khi xoá.',
			NO_MORE_DASHBOARD: 'Không có bảng điều khiển.',
			SWITCH_DIALOG_LABEL: 'Hãy chọn bảng điều khiển',
			NO_MORE_REPORT: 'Không có báo cáo nào.',
			DELETE_REPORT_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá báo cáo này? Xin lưu ý bạn không thể khôi phục lại sau khi xoá.',
			DELETE_PARAGRAPH_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá đoạn này? Hãy lưu ý rằng bạn không thể khôi phục lại sau khi xoá.',
			NO_REPORT: 'Không có báo cáo.',
			SHOW_PRINT_PAGE: 'Hiển thị cỡ trang',
			HIDE_PRINT_PAGE: 'Ẩn cỡ trang',
			SET_AS_ADMIN_HOME: 'Cài đặt làm trang chủ',
			FUNNEL_TITLE: 'Phễu'
		},
		CONNECTED_SPACE: {
			CATALOG: 'Cẩm nang',
			SUBJECT_DEF: 'Định nghĩa',
			SUBJECT_DATA: 'Tệp số liệu',
			SUBJECT_REPORTS: 'Báo cáo',
			SUBJECT_REPORT: 'Báo cáo',
			REPORT_DATA: 'Tệp số liệu',
			REPORT_FILTER: 'Lọc',
			REPORT_FUNNEL: 'Phễu',
			SHARE: 'Chia sẻ',
			ADD_REPORT: 'Thêm báo cáo',
			ADD_SUBJECT: 'Thêm đối tượng',
			OPEN_SUBJECT: 'Mở đối tượng',
			ADD_CONNECTED_SPACE: 'Kết nối không gian',
			SWITCH_CONNECTED_SPACE: 'Tráo đổi không gian',
			DELETE_ME: 'Xoá',
			ADD_INTO_FAVORITE: 'Thêm vào mục yêu thích',
			REMOVE_FROM_FAVORITE: 'Bỏ khỏi mục yêu thích',
			SET_AS_TEMPLATE: 'Cài đặt thành mẫu',
			REMOVE_FROM_TEMPLATE: 'Xoá khỏi mẫu',
			DELETE_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá không gian kết nối? Hãy lưu ý rằng bạn không thể khôi phục lại sau khi xoá.',
			NO_MORE_CONNECTED_SPACE: 'Không còn không gian kết nối.',
			SWITCH_DIALOG_LABEL: 'Hãy chọn không gian kết nối',
			CREATE_DIALOG_LABEL: 'Hãy chọn không gian kết nối hiện có',
			CREATE_DIALOG_CHOOSE_TEMPLATE_LABEL: 'Hãy chọn mẫu yêu thích',
			TEMPLATE: 'Mẫu',
			TEMPLATE_CREATE_BY: 'Tạo bởi',
			NO_MORE_SPACE: 'Không tổn tại không gian, vui lòng liên hệ Admin để biết thêm chi tiết.',
			SPACE_NOT_FOUND: 'Không tổn tại định nghĩa không gian, vui lòng liên hệ Admin để biết thêm chi tiết.',
			TOPICS_NOT_FOUND: 'Không tổn tại định nghĩa không gian, vui lòng liên hệ Admin để biết thêm chi tiết.',
			TOPICS_COUNT_MISMATCH: 'Không khớp chủ đề, vui lòng liên hệ Admin để biết thêm chi tiết.',
			TOPIC_WITH_NO_FACTOR: 'Không tìm thấy yếu tố, vui lòng liên hệ Admin để biết thêm chi tiết.',
			DELETE_SUBJECT_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá chủ đề? Hãy lưu ý rằng bạn không thể khôi phục lại sau khi xoá.',
			SWITCH_SUBJECT: 'Đổi chủ đề',
			SWITCH_SUBJECT_DIALOG_LABEL: 'Hãy chọn chủ đề',
			NO_MORE_SUBJECT: 'Không còn tồn tại chủ đề',
			SUBJECT_DEF_INVALID: 'Định nghĩa chủ đề không đúng, vui lòng sửa lại',
			SUBJECT_PICK_TOPICS: 'Chọn chủ đề',
			SUBJECT_DEFINE_COLUMNS: 'Định nghĩa cột',
			SUBJECT_FILTER_DATA: 'Lọc số liệu',
			SUBJECT_SET_JOINS: 'Cài đặt Joins',
			SUBJECT_DEF_OVERVIEW: 'Overview',
			NO_PICKED_TOPIC_FOR_SUBJECT: 'Chưa có chủ đề nào được chọn.',
			NO_DATASET_COLUMN_FOR_SUBJECT: 'Chưa định nghĩa cột, bạn có muốn ',
			CREATE_DATASET_COLUMN_WHEN_NONE: 'Tạo',
			NO_DATASET_COLUMN_FOR_SUBJECT_2: '?',
			ALIAS: 'As',
			SUBJECT_COLUMN_ARITHMETIC: 'Gộp',
			SUBJECT_COLUMN_ARITHMETIC_NONE: 'Giữ nguyên',
			SUBJECT_COLUMN_ARITHMETIC_COUNT: 'Đếm',
			SUBJECT_COLUMN_ARITHMETIC_SUMMARY: 'Tổng',
			SUBJECT_COLUMN_ARITHMETIC_AVERAGE: 'Bình quân',
			SUBJECT_COLUMN_ARITHMETIC_MAX: 'Lớn nhất',
			SUBJECT_COLUMN_ARITHMETIC_MIN: 'Nhỏ nhất',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT: 'Căn lề',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_LEFT: 'Trái',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_CENTER: 'Giữa',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_RIGHT: 'Phải',
			SUBJECT_COLUMN_RENDERER_FORMAT: 'Định dạng',
			SUBJECT_COLUMN_RENDERER_FORMAT_NONE: 'Giữ nguyên',
			SUBJECT_COLUMN_RENDERER_HIGHLIGHT_NEGATIVE: 'Làm nổi bật số âm',
			SUBJECT_COLUMN_POSITION_ALREADY_AT_FIRST: 'Không thể rời lên trên vì đã ở trên cùng',
			SUBJECT_COLUMN_POSITION_ALREADY_AT_LAST: 'Không thể rời xuống dưới vì đã ở dưới cùng',
			ADD_SUBJECT_COLUMN: 'Thêm cột',
			ADD_SUBJECT_RECALCULATE_COLUMN: 'Thêm cột tính lại',
			CAN_NOT_DELETE_CHILD_FROM_COMPUTED: 'Không thể xoá vì chạm tới tham số nhỏ nhất',
			CAN_NOT_ADD_CHILD_INTO_COMPUTED: 'Không thể thêm vì chạm tới tham số lớn nhất.',
			ADD_COMPUTE_PARAMETER: 'Thêm tham số',
			NO_DATASET_FILTER_FOR_SUBJECT: 'Chưa định nghĩa bọ lọc, bạn có muốn',
			CREATE_DATASET_FILTER_WHEN_NONE: 'Tạo',
			NO_DATASET_FILTER_FOR_SUBJECT_2: '?',
			ADD_SUB_EXPRESSION_FILTER: 'Thêm Sub Expression',
			ADD_SUB_JOINT_FILTER: 'Thêm Sub Joint',
			NO_DATASET_JOIN_FOR_SUBJECT: 'Không có định nghĩa join nào, bạn có muốn ',
			CREATE_DATASET_JOIN_WHEN_NONE: 'Tạo',
			NO_DATASET_JOIN_FOR_SUBJECT_2: '?',
			ADD_SUBJECT_JOIN: 'Thêm Join',
			SUBJECT_SELECT: 'Chọn',
			SUBJECT_NO_SELECT: 'Chưa cột nào được định nghĩa',
			SUBJECT_FROM: 'Từ',
			SUBJECT_NO_FROM: 'Không có join được định nghĩa.',
			SUBJECT_JOIN_INNER: 'Trong Join',
			SUBJECT_JOIN_LEFT: 'Join trái',
			SUBJECT_JOIN_RIGHT: 'Join phải',
			SUBJECT_JOIN_ON: 'Trên',
			SUBJECT_JOIN_EQUALS: '=',
			SUBJECT_JOIN_AND: 'và',
			SUBJECT_WHERE: 'Tại',
			SUBJECT_NO_WHERE: 'Chưa định nghĩa bộ lọc.',
			SUBJECT_UNKNOWN_PARAMETER: 'Tham số không xác định',
			SUBJECT_EMPTY_CONSTANT: 'Giá trị bất biến trống',
			SUBJECT_COLUMN_AS: 'Là',
			SUBJECT_NO_ALIAS: '?',
			SUBJECT_FILTER_JOINT_NO_STATEMENT: 'Không Statement in Joint',
			SUBJECT_UNKNOWN_FILTER: 'Bộ lọc chưa xác định',
			NO_DATASET_COLUMN: 'Chưa có cột nào được định nghĩa.',
			NO_REPORT: 'Không có báo cáo được định nghĩa, ban có muốn ',
			CREATE_REPORT: 'Tạo',
			NO_REPORT_2: '?',
			NO_MORE_REPORT: 'Không có báo cáo',
			OPEN_REPORT: 'Mở báo cáo',
			SWITCH_REPORT: 'Trao đổi báo cáo',
			SWITCH_REPORT_DIALOG_LABEL: 'Vui lòng chọn báo cáo',
			DELETE_REPORT_DIALOG_LABEL: 'Bạn có chắc chắn muốn xoá báo cáo? Hãy lưu ý rằng bạn không thể khôi phục lại sau khi xoá.',
			COLLAPSE_REPORT_SETTINGS_SECTIONS: 'Lồng tất cả các phần',
			EXPAND_REPORT_SETTINGS_SECTIONS: 'Mở rộng tất cả các phần',
			REPORT_NO_FILTER: 'Không có bộ lọc được định nghĩa, bạn có muốn ',
			CREATE_REPORT_FILTER: 'Tạo',
			REPORT_NO_FILTER_2: '?',
			REPORT_NO_FUNNEL: 'Không có phễu.'
		}
	},
	INDICATOR: {
		MENU: {
			TITLE: 'Chỉ bro Watchmen',
			BUCKETS: 'Buckets',
			INDICATORS: 'Chỉ báo',
			OBJECTIVES: 'Đối tượng',
			SETTINGS: 'Cài đặt',
			LOGOUT: 'Đăng xuất'
		},
		ON_EDIT: 'Bạn đang sửa, tất cả thay đổi sẽ không được lưu lại nếu bị gián đoạn. Bạn có chắc chắn muốn tiếp tục?',
		STEP: 'Bước',
		INDICATOR: {
			LIST_TITLE: 'Chỉ báo',
			LIST_CREATE_INDICATOR: 'Định nghĩa chỉ báo',
			LIST_LABEL: 'Chỉ báo',
			NEW_INDICATOR_PREFIX: 'Mới',
			EXISTING_INDICATOR_PREFIX: 'Có sẵn',
			CREATE_AT: 'Tạo tại',
			LAST_MODIFIED_AT: 'Lần cuối chỉnh sửa',
			TITLE: 'Định nghĩa chỉ báo',
			WAIT_INPUT: 'Đợi nhập...',
			SEARCHING: 'Tìm...',
			NO_MATCHED: 'Không có số liệu khớp...',
			OR: 'hoặc',
			CREATE_INDICATOR: 'Định nghĩa mới',
			FIND_INDICATOR: 'Tìm đã có',
			DISCARD_FIND_INDICATOR: 'Bỏ tìm kiếm',
			ON_CREATE_INDICATOR: 'Định nghĩa 1 chỉ báo',
			ON_VIEW_INDICATOR: 'Xem chỉ báo',
			RESTART: 'Khởi động lại',
			BACK_TO_LIST: 'Quay lại danh sách',
			PICK_TOPIC_OR_SUBJECT: 'Tìm chủ đề',
			DEFINE_ON_TOPIC: 'Tìm chủ đề',
			DEFINE_ON_SUBJECT: 'Định nghĩa chủ đề',
			INDICATOR_ON_TOPIC: 'Trong chủ đề',
			INDICATOR_ON_SUBJECT: 'Trong chủ đề',
			FAILED_TO_LOAD_INDICATOR: 'Tải chỉ báo thất bại, vui lòng liên hệ Admin để biết thêm chi tiết.',
			AGGREGATE_ITEM_TITLE: 'Chọn một chức năng.',
			MEASURE_METHODS_TITLE: 'Thước đo có sẵn cho chỉ báo này được tìm thấy tự động.',
			FACTOR: 'Yếu tố',
			FACTOR_NAME: 'Tên',
			FACTOR_LABEL: 'Mác',
			FACTOR_TYPE: 'Loại',
			FACTOR_ENUM: 'Đếm',
			GEO: 'GEO',
			TIME_PERIOD: 'Khoảng thời gian',
			INDIVIDUAL: 'Cá nhân',
			ORGANIZATION: 'Tổ chức',
			CATEGORY: 'Phân loại',
			DEFINE_BUCKETS_TITLE: 'Buckets được định nghĩa sẵn để phân loại dễ dàng hơn',
			INDICATOR_VALUE_BUCKET_ONLY_ON_FACTOR_LABEL: 'Giá trị bucket có sẵn cho từng chỉ báo yếu tố',
			INDICATOR_VALUE_BUCKET_LABEL: 'Giá trị chỉ báo bucketing',
			LINK_INDICATOR_VALUE_BUCKETS: 'Liên kết được định nghĩa giá trị Buckets với giá trị chỉ báo',
			DISCARD_LINK_INDICATOR_VALUE_BUCKETS: 'Bỏ link',
			MEASURE_BUCKET_LABEL: 'Đo bucketing',
			NO_MEASURE_BUCKET: 'Không có thước đo bucket.',
			VIEW_MEASURE_BUCKETS: 'Xem Buckets đã đo được định nghĩa sẵn',
			IGNORE_DEFINE_BUCKETS: 'Bỏ qua định nghĩa Buckets',
			CHANGE_NAME: 'Thay đổi tên',
			NOT_NOW: 'Lúc khác',
			SAVE_INDICATOR: 'Lưu chỉ báo',
			SAVE_NAME: 'Lưu tên',
			REQUIRE_INDICATOR_NAME: 'Tên của chỉ báo được yêu cầu.',
			RELEVANT_TITLE: 'Chỉ báo có liên quan của chỉ báo hiện tại có thể được tìm thấy tự động',
			INDICATOR_NAME: 'Chỉ báo',
			INDICATOR_RELEVANT_TYPE: 'Loại liên quan',
			IRRELEVANT: 'Không liên quan',
			NO_RELEVANT_DETECTED: 'Không có chỉ báo nào có liên quan được tìm thấy.',
			DETECT_RELEVANT: 'Tìm chỉ báo tiềm năng',
			IGNORE_DETECT_RELEVANT: 'Bỏ qua',
			FILTERS_ON_TOPIC: 'Hạn chế số liệu.',
			FILTERS_ON_SUBJECT: 'Hạn chế số liệu đã được định nghĩa trong chủ đề và không gian, sẽ được quy định bởi chỉ báo.',
			CATEGORIES_TITLE: 'Phan loại.',
			DESCRIPTION_TITLE: 'Mô tả.',
			LAST_STEP_TITLE: 'Chỉ báo đã sẵn sàng',
			PREPARE_ANOTHER: 'Định nghĩa một cái khác'
		},
		BUCKET: {
			TITLE: 'Buckets',
			LABEL: 'Bucket',
			NEW_BUCKET_PREFIX: 'Mới',
			EXISTING_BUCKET_PREFIX: 'Có sẵn',
			CREATE_BUCKET: 'Tạo Bucket',
			NAME_LABEL: 'Tên Bucket:',
			TYPE_LABEL: 'Loại Bucket:',
			TYPE_IS_FIXED_ONCE_SAVE: 'Loại Bucket không thể thay đổi sau khi lưu.',
			DESCRIPTION_LABEL: 'Mô tả:',
			CREATE_AT: 'Tạo tại',
			LAST_MODIFIED_AT: 'Ngày sửa gần nhất',
			BUCKET_NAME_IS_REQUIRED: 'Tên người được yêu cầu.',
			BUCKET_MEASURE_IS_REQUIRED: 'Vui lòng chọn phương thức đo.',
			BUCKET_ENUM_IS_REQUIRED: 'Vui lòng chọn kiểu dữ liệu.',
			BUCKET_SEGMENTS_IS_REQUIRED: 'Ít nhất hai phần chưa được định nghĩa',
			BUCKET_SEGMENT_NAME_IS_REQUIRED: 'Tên người được yêu cầu',
			BE_NUMERIC_OF_NUMERIC_SEGMENT: 'Giá trị phải là dạng số',
			EMPTY_FIRST_MIN_OF_NUMERIC_SEGMENT: 'Giá trị nhỏ nhất của phần đầu tiên không được để trống.',
			NOT_EMPTY_FIRST_MAX_OF_NUMERIC_SEGMENT: 'Giá trị lớn nhất của phần đầu tiên không được để trống.',
			NOT_EMPTY_OF_NUMERIC_SEGMENT: 'Giá trị của phần ở giữa không được để trống.',
			MIN_MAX_ORDER_OF_NUMERIC_SEGMENT: 'Giá trị nhỏ nhất không được nhỏ hơn giá trị lớn nhất.',
			NOT_EMPTY_LAST_MIN_OF_NUMERIC_SEGMENT: 'Giá trị nhỏ nhất của phần cuối phải để trống.',
			EMPTY_LAST_MAX_OF_NUMERIC_SEGMENT: 'Giá trị lớn nhất của phần cuối phải để trống.',
			RANGE_LACK_EXISTS_OF_NUMERIC_SEGMENT: 'Có thiếu khoảng.',
			RANGE_OVERLAP_EXISTS_OF_NUMERIC_SEGMENT: 'Có khoảng chồng chéo.',
			NOT_EMPTY_OF_CATEGORY_SEGMENT: 'Cần định nghĩa ít nhất một loại.',
			NO_DUPLICATED_OF_CATEGORY_SEGMENT: 'Không thể định nghĩa loại trùng lặp trong một phần.',
			ONE_OTHERS_SEGMENT_OF_CATEGORY_SEGMENT: 'Chỉ một phần có thể chứa loại "Khác".',
			ONE_OTHERS_VALUE_OF_CATEGORY_SEGMENT: 'Chỉ có một loại "Khác" trong phần.',
			NO_SHARED_OF_CATEGORY_SEGMENT: 'Không thể định nghĩa cùng loại trong nhiều phần.',
			NO_SEGMENT_DEFINED: 'Không có phần được định nghĩa',
			ONE_SEGMENT_DEFINED: '1 Phần',
			N_SEGMENT_DEFINED: 'Các phần',
			BUCKET_TYPE_VALUE: 'Giá trị',
			BUCKET_TYPE_VALUE_MEASURE: 'Giá trị đo',
			BUCKET_TYPE_CATEGORY_MEASURE: 'Loại đo',
			BUCKET_TYPE_ENUM_MEASURE: 'Đo Enumeration',
			RANGE_INCLUDING_LABEL: 'Giá trị Inclusion Mode:',
			RANGE_INCLUDE_MIN: '[Gồm giá trị nhỏ nhất, Không gồm giá trị lớn nhất)',
			RANGE_INCLUDE_MAX: '(Không gồm giá trị lớn nhất, Gồm Giá trị lớn nhất]',
			SEGMENTS_LABEL: 'Phần:',
			SEGMENT_NAME: 'Tên phần',
			VALUE_SEGMENT_MIN_LABEL: 'Giá trị nhỏ nhất',
			VALUE_SEGMENT_MAX_LABEL: 'Giá trị lớn nhất',
			ADD_SEGMENT: 'Thêm phần',
			SORT_SEGMENTS: 'Phân loại phần',
			MEASURE_METHOD_LABEL: 'Phương pháp đo',
			MEASURE_METHOD_IS_FIXED_ONCE_SAVE: 'Phương pháp đo không thể thay đổi sau khi lưu.',
			CATEGORY_SEGMENT_LABEL: 'Giá trị phân loại',
			DUPLICATE_CATEGORY_SEGMENT_VALUE: 'Loại này đã tồn tại trong phần.',
			DUPLICATE_CATEGORY_SEGMENT_VALUE_CASE_IGNORED: 'Loại này đã tồn tại trong phần này, bạn có chắc chắn muốn thêm?',
			OTHERS_IS_EXCLUSIVE_ON_CATEGORY_SEGMENT: 'Loại "khác" đã tồn tại trong phần này, bạn có chắc chắn muốn thêm.',
			NO_SEGMENT_VALUE_DEFINED: 'Không có giá trị được định nghĩa',
			ADD_OTHER_CATEGORY: 'Thêm phần cho Khác',
			ENUM_LABEL: 'Enumeration:',
			ENUM_IS_FIXED_ONCE_SAVE: 'Enumeration không thể thay đổi sau khi lưu.',
			SORT_SEGMENTS_BY_CODE: 'Phân loại phần bằng mã',
			SORT_SEGMENTS_BY_NAME: 'Phân loại phần bằng tên',
			AVAILABLE_ENUM_ITEMS_LABEL: 'Enumeration Items có sẵn:',
			NO_AVAILABLE_ENUM_ITEMS: 'Không có items nào.',
			ADD_AVAILABLE_ITEMS_INTO_SEGMENT: 'Thêm vào phần',
			SEGMENT_LABEL: 'Phần',
			PLEASE_SELECT_SEGMENT: 'Vui lòng chọn một phần để thêm enumeration items này.',
			PLEASE_SELECT_ENUM_ITEM: 'Vui lòng chọn ít nhất một enumeration item để thêm vào phần.'
		},
		OBJECTIVE: {
			LIST_TITLE: 'Mục tiêu',
			LIST_CREATE_OBJECTIVE: 'Định nghĩa mục tiêu',
			LIST_LABEL: 'Mục tiêu',
			NEW_OBJECTIVE_PREFIX: 'Mới',
			EXISTING_OBJECTIVE_PREFIX: 'Có sẵn',
			CREATE_AT: 'Tạo lúc',
			LAST_MODIFIED_AT: 'Lần sửa gần nhất lúc',
			TITLE: 'Định nghĩa mục tiêu',
			FAILED_TO_LOAD_OBJECTIVE: 'Tải mục tiêu thất bại, vui lòng liên hệ Admin để biết thêm chi tiết.',
			TARGETS_TITLE: 'Khai báo giá trị mục tiêu định lượng.',
			BACK_TO_LIST: 'Quay lại danh sách',
			TARGET_NAME: ':',
			TARGET_TOBE: 'Để trở thành giá trị:',
			TARGET_TOBE_PLACEHOLDER: 'Giá trị số hoặc giá trị phần trăm (kết thúc bằng %).',
			TARGET_ASIS: 'Như là giá trị:',
			TARGET_ASIS_REFER: 'Tham khảo',
			TARGET_ASIS_COMPUTED: 'Tính toán',
			TARGET_BETTER_SIDE: 'Giá trị tốt hơn:',
			TARGET_BETTER_SIDE_MORE: 'Nhiều hơn là tốt hơn',
			TARGET_BETTER_SIDE_LESS: 'Ít hơn là tốt hơn',
			TARGET_ASK_PREVIOUS_CYCLE: 'Bao gồm chu kỳ trước (ví dụ: hàng tháng)?',
			TARGET_ASK_CHAIN_CYCLE: 'Bao gồm chu kỳ chuỗi (ví dụ: hàng năm)?',
			TARGET_VALUES: 'Giá trị:',
			TARGET_CURRENT_VALUE: 'Hiện tại',
			TARGET_PREVIOUS_VALUE: 'Trước đó',
			TARGET_CHAIN_VALUE: 'Chuỗi',
			TARGET_FINISH_RATE: 'Tỷ lệ',
			ADD_TARGET: 'Thêm mục tiêu mới',
			TARGET_CONSANGUINITY: 'Consanguinity',
			TIME_FRAME_TITLE: 'Đặt khung thời gian.',
			TIME_FRAME_KIND: 'Loại:',
			TIME_FRAME_KIND_NONE: 'Không liên quan đến khung thời gian',
			TIME_FRAME_KIND_YEAR: 'Năm',
			TIME_FRAME_KIND_HALF_YEAR: 'Nửa năm',
			TIME_FRAME_KIND_QUARTER: 'Quý',
			TIME_FRAME_KIND_MONTH: 'Tháng',
			TIME_FRAME_KIND_WEEK_OF_YEAR: 'Tuần',
			TIME_FRAME_KIND_DAY_OF_MONTH: 'Ngày, Của tháng',
			TIME_FRAME_KIND_DAY_OF_WEEK: 'Ngày, Của tuần',
			TIME_FRAME_KIND_LAST_N_YEARS: 'N Năm qua',
			TIME_FRAME_KIND_LAST_N_MONTHS: 'N Tháng qua',
			TIME_FRAME_KIND_LAST_N_WEEKS: 'N Tuần qua',
			TIME_FRAME_KIND_LAST_N_DAYS: 'N Ngày qua',
			TIME_FRAME_N_IS: 'N là:',
			TIME_FRAME_TILL: 'Tới khi:',
			TIME_FRAME_TILL_NOW: 'Bây giờ',
			TIME_FRAME_TILL_LAST_COMPLETE_CYCLE: 'Chu kỳ hoàn chỉnh cuối cùng',
			TIME_FRAME_TILL_SPECIFIED: 'Đã chỉ định',
			TIME_FRAME_TILL_SPECIFIED_AT: ':',
			TIME_FRAME_TILL_SPECIFIED_AT_DESC: 'Đây là ngày cuối cùng của khung thời gian quy định, khung được ngoại suy về phía trước từ ngày được chỉ định',
			TIME_FRAME_CALCULATED: 'Khung hiện tại:',
			TIME_FRAME_PREVIOUS_CYCLE: 'Khung hình trước:',
			TIME_FRAME_CHAIN_CYCLE: 'FKhung trong chu kỳ trước:',
			VARIABLES_TITLE: 'Biến.',
			VARIABLE_IS: 'là',
			VARIABLE_KIND_SINGLE_VALUE: 'Single Value',
			VARIABLE_KIND_RANGE: 'Range Values',
			VARIABLE_KIND_BUCKET: 'From Bucket',
			VARIABLE_RANGE_TO: '..',
			VARIABLE_RANGE_INCLUDE_MIN: '[',
			VARIABLE_RANGE_NOT_INCLUDE_MIN: '(',
			VARIABLE_RANGE_INCLUDE_MAX: ']',
			VARIABLE_RANGE_NOT_INCLUDE_MAX: ')',
			VARIABLE_BUCKET_PLACEHOLDER: 'Một bucket...',
			VARIABLE_BUCKET_INCORRECT_SELECTED: 'Đã chọn xô không xác định.',
			VARIABLE_BUCKET_NO_AVAILABLE: 'Không có bucket sẵn.',
			VARIABLE_BUCKET_SEGMENT_PLACEHOLDER: 'Một phần...',
			VARIABLE_BUCKET_SELECT_FIRST: 'Chọn bucket .',
			VARIABLE_BUCKET_SEGMENT_INCORRECT_SELECTED: 'Đã chọn phân đoạn không xác định.',
			VARIABLE_BUCKET_SEGMENT_NO_AVAILABLE: 'Không có phần khả dụng.',
			ADD_VARIABLE: 'Thêm biến mới',
			REMOVE_VARIABLE: 'Remove Variable',
			FACTORS_TITLE: 'Measure by indicators.',
			FACTOR_INDICATOR: 'Indicator',
			FACTOR_INDICATOR_FILTER: 'Filter',
			FACTOR_FORMULA: 'Formula',
			FACTOR_REFER_SELF: 'Myself',
			REFER_INDICATOR_PLACEHOLDER: 'An indicator...',
			REFER_INDICATOR_BUT_INCORRECT: 'Unknown indicator',
			REFER_INDICATOR_BUT_NO_AVAILABLE: 'No available indicator.',
			REFER_INDICATOR_BUT_NOT_READY: 'is available only when indicator selected.',
			REFER_INDICATOR_ON_NO_FILTER: 'On all data',
			REFER_INDICATOR_ON_TOPIC_FILTER_PLACEHOLDER: 'A factor...',
			REFER_INDICATOR_ON_SUBJECT_FILTER_PLACEHOLDER: 'A column...',
			REFER_INDICATOR_FILTER_ON_INCORRECT_FACTOR_OR_COLUMN: 'Unknown selection',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_FACTOR_OR_COLUMN: 'No available candidate.',
			REFER_INDICATOR_FILTER_ON_INCORRECT_BUCKET: 'Unknown bucket',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_BUCKET: 'No available bucket.',
			REFER_INDICATOR_FILTER_BUCKET_PLACEHOLDER: 'A bucket...',
			REFER_INDICATOR_FILTER_BUCKET_FIRST: 'Select bucket first.',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_BUCKET_SEGMENT: 'No available segment.',
			REFER_INDICATOR_FILTER_BUCKET_SEGMENT_PLACEHOLDER: 'A segment...',
			ADD_INDICATOR: 'Add Indicator',
			ADD_COMPUTED_INDICATOR: 'Add Computation',
			NOT_NOW: 'Not Now',
			SAVE_OBJECTIVE: 'Save Objective',
			SAVE_NAME: 'Save Name',
			REQUIRE_OBJECTIVE_NAME: 'A human reading name for objective is required.',
			CHANGE_NAME: 'Change Name',
			NAME_TITLE: 'Set a human reading name.',
			DESCRIPTION_TITLE: 'Description.',
			USER_GROUP_TITLE: 'Grant to user group.',
			GRANT_USER_GROUP: 'Grant Access',
			REFER_FACTOR_PLACEHOLDER: 'An indicator...',
			REFER_FACTOR_BUT_NONAME: 'Noname Indicator',
			REFER_FACTOR_BUT_INCORRECT: 'Unknown indicator',
			REFER_FACTOR_BUT_NO_AVAILABLE: 'No available indicator.',
			ADD_SUB_PARAMETER: 'Add Parameter',
			CANNOT_ADD_COMPUTATION_PARAMETER: 'Cannot add more because of reach maximum parameter(s).',
			CANNOT_DELETE_COMPUTATION_PARAMETER: 'Cannot delete this because of reach minimum parameter(s).',
			EXPRESSION: 'Expression',
			ADD_SUB_EXPRESSION: 'Add Sub Expression',
			ADD_SUB_JOINT: 'Add Sub Joint',
			TEST_FACTOR: 'Value',
			TEST_VALUE_CLICK: 'Click to retrieve values',
			TEST_VALUE_GET_NONE: 'No data retrieved from server side.'
		}
	},
	SUBSCRIBE: {
		ON: 'Subscribe On',
		BY_MAIL: 'Email',
		MAIL_ADDRESS: 'Mail Address',
		BY_SLACK: 'Slack',
		SLACK_CHANNEL: 'Slack Channel',
		FREQUENCY: 'Frequency',
		DAILY: 'Daily',
		WEEKLY: 'Weekly',
		MONTHLY: 'Monthly'
	},
	SETTINGS: {
		TITLE: 'Settings',
		LANGUAGE: 'Language',
		THEME: 'Theme',
		THEME_LIGHT: 'Light',
		THEME_LIGHT_CONTRAST_BIGGER: 'Light Contrast Bigger',
		THEME_DARK: 'Dark',
		THEME_DARK_CONTRAST_BIGGER: 'Dark Contrast Bigger',
		PAT: {
			TITLE: 'Personal Access Token',
			CREATE: 'Generate New Token',
			DESCRIPTION: 'Tokens you have generated that can be used to access the Watchmen API.',
			DELETE_CONFIRM: 'Are you sure to delete token? Please note that deletion cannot be recovered.',
			INPUT_PLACEHOLDER: 'A note for identify purpose of token',
			NOTE_REQUIRED: 'Note is required for a token.'
		}
	},
	PARAM: {
		FROM: 'From',
		FROM_TOPIC: 'Topic',
		FROM_CONSTANT: 'Constant',
		FROM_COMPUTED: 'Compute',
		FROM_INDICATOR: 'Refer',
		FROM_BUCKET: 'Bucket',
		FROM_TIME_FRAME: 'Time Frame'
	},
	FACTOR: {
		SEQUENCE: 'Sequence',
		NUMBER: 'Numeric',
		UNSIGNED: 'Unsigned Numeric',
		TEXT: 'Text',
		ADDRESS: 'Address',
		CONTINENT: 'Continent',
		REGION: 'Region',
		COUNTRY: 'Country',
		PROVINCE: 'State/Province',
		CITY: 'City',
		DISTRICT: 'District',
		ROAD: 'Road',
		COMMUNITY: 'Community',
		FLOOR: 'Floor',
		RESIDENCE_TYPE: 'Residence Type',
		RESIDENTIAL_AREA: 'Residential Area',
		EMAIL: 'Email',
		PHONE: 'Phone',
		MOBILE: 'Mobile',
		FAX: 'Fax',
		DATETIME: 'DateTime',
		FULL_DATETIME: 'Full DateTime',
		DATE: 'Date',
		TIME: 'Time',
		YEAR: 'Year',
		HALF_YEAR: '1st/2nd Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: '1st/2nd Half Month',
		TEN_DAYS: '1st/2nd/3rd Ten Days',
		WEEK_OF_YEAR: 'Week of Year',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: '1st/2nd Half Week',
		DAY_OF_MONTH: 'Day of Month',
		DAY_OF_WEEK: 'Day of Week',
		DAY_KIND: 'Workday/Weekday/Holiday',
		HOUR: 'Hour',
		HOUR_KIND: 'Work Time/Off Hours/Sleep Time',
		MINUTE: 'Minute',
		SECOND: 'Second',
		MILLISECOND: 'Millisecond',
		AM_PM: 'AM/PM',
		GENDER: 'Gender',
		OCCUPATION: 'Occupation',
		DATE_OF_BIRTH: 'Date of Birth',
		AGE: 'Age',
		ID_NO: 'ID No.',
		RELIGION: 'Religion',
		NATIONALITY: 'Nationality',
		BIZ_TRADE: 'Business Trade',
		BIZ_SCALE: 'Business Scale',
		BOOLEAN: 'Boolean',
		ENUM: 'Enumeration',
		OBJECT: 'Object',
		ARRAY: 'Array'
	},
	PARAMETER: {
		EXPRESSION: 'Expression',
		COMPUTE_TYPE: {
			NONE: 'As is',
			ADD: 'Add',
			SUBTRACT: 'Subtract',
			MULTIPLY: 'Multiply',
			DIVIDE: 'Divide',
			MODULUS: 'Modulus',
			YEAR_OF: 'Year of',
			HALF_YEAR_OF: 'Half Year of',
			QUARTER_OF: 'Quarter of',
			MONTH_OF: 'Month of',
			WEEK_OF_YEAR: 'Week of Year',
			WEEK_OF_MONTH: 'Week of Month',
			DAY_OF_MONTH: 'Day of Month',
			DAY_OF_WEEK: 'Day of Week',
			CASE_THEN: 'Case Then',
			ROUND: 'Round',
			ROUND_DIGITS: 'Digits',
			FLOOR: 'Floor',
			CEIL: 'Ceil',
			ABS: 'Abs',
			MAX: 'Max of',
			MIN: 'Min of',
			INTERPOLATE: 'Linear Interpolate',
			INTERPOLATE_MIN_VALUE: 'Bottom Value',
			INTERPOLATE_MIN_VALUE_TO: 'Bottom Value Map To',
			INTERPOLATE_MAX_VALUE: 'Top Value',
			INTERPOLATE_MAX_VALUE_TO: 'Top Value Map To'
		},
		EXPRESSION_OPERATOR: {
			EMPTY: 'Is Empty',
			NOT_EMPTY: 'Has Value',
			EQUALS: 'Equals',
			NOT_EQUALS: 'Not Equals',
			LESS: 'Less than',
			LESS_EQUALS: 'Less than or Equals',
			MORE: 'Greater than',
			MORE_EQUALS: 'Greater than or Equals',
			IN: 'In',
			NOT_IN: 'Not In'
		}
	},
	JOINT: {
		AND: 'And',
		OR: 'OR'
	},
	JOIN: {
		INNER: 'Exactly Match',
		LEFT: 'Left Side Prioritized',
		RIGHT: 'Right Side Prioritized'
	},
	FUNNEL: {
		COLUMN: 'Column - Type',
		RANGE: 'Range?',
		ENABLED: 'Enable?',
		NUMERIC: 'Numeric',
		DATE: 'Date',
		YEAR: 'Year',
		HALF_YEAR: 'Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: 'Half Month',
		TEN_DAYS: 'Ten Days',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: 'Half Week',
		DAY_KIND: 'Day Kind',
		DAY_OF_WEEK: 'Day of Week',
		HOUR: 'Hour',
		HOUR_KIND: 'Hour Kind',
		AM_PM: 'AM/PM',
		ENUM: 'Enumeration'
	},
	PAGINATION: {
		TOTAL: '',
		ROWS: 'Row(s) Total',
		OF_PAGES: 'of',
		PAGES: 'Pages'
	},
	CHART: {
		SETTINGS_HEADER_LABEL: 'Report Settings',
		SECTION_TITLE_BASIC_STYLE: 'Basic Style',
		SECTION_TITLE_DIMENSIONS: 'Dimensions',
		SECTION_TITLE_INDICATORS: 'Indicators',
		SECTION_TITLE_BASIC: 'Basic',
		SECTION_TITLE_PALETTE_SIZE: 'Palette Size',
		SECTION_TITLE_COLOR: 'Color Rendering',
		SECTION_TITLE_BORDER: 'Border Rendering',
		SECTION_TITLE_FONT: 'Font',
		SECTION_TITLE_POSITION: 'Position',
		SECTION_TITLE_PADDING: 'Padding',
		SECTION_TITLE_GAP_AND_PADDING: 'Gap & Padding',
		SECTION_TITLE_VALUE_FORMAT: 'Value Format',
		SECTION_TITLE_FUNNEL_DEFINITION: 'Funnel Definition',
		DIMENSIONS: 'Dimensions',
		INDICATORS: 'Indicators',
		FILTERS: 'Filters',
		NO_FUNNEL_DETECTED: 'No available funnel detected.',
		FUNNEL_DESCRIPTION: 'Available funnels are auto detected, enable them to adjust report data dynamically here and in dashboard.',
		FUNNEL_INSTANCE_DESCRIPTION: 'Open palette to define funnel values.',
		DESCRIPTION: 'Description',
		WIDTH: 'Width',
		HEIGHT: 'Height',
		PIXEL: 'PX',
		PERCENTAGE: '%',
		DEGREE: 'DEG',
		LINE_COLOR: 'Line Color',
		LINE_STYLE: 'Line Style',
		FONT_FAMILY: 'Font',
		FONT_COLOR: 'Font Color',
		FONT_SIZE: 'Font Size',
		FONT_STYLE: 'Font Style',
		FONT_STYLE_NORMAL: 'Normal',
		FONT_STYLE_ITALIC: 'Italic',
		FONT_WEIGHT: 'Font Weight',
		FONT_WEIGHT_100: '100',
		FONT_WEIGHT_200: '200',
		FONT_WEIGHT_300: '300',
		FONT_WEIGHT_400: '400',
		FONT_WEIGHT_500: '500',
		FONT_WEIGHT_600: '600',
		FONT_WEIGHT_700: '700',
		FONT_WEIGHT_800: '800',
		FONT_WEIGHT_900: '900',
		BACKGROUND_COLOR: 'Background Color',
		BORDER_STYLE: 'Border Style',
		BORDER_STYLE_NONE: 'None',
		BORDER_STYLE_SOLID: 'Solid',
		BORDER_STYLE_DASHED: 'Dashed',
		BORDER_STYLE_DOTTED: 'Dotted',
		BORDER_COLOR: 'Border Color',
		BORDER_WIDTH: 'Border Width',
		BORDER_RADIUS: 'Border Radius',
		POSITION: 'Position',
		POSITION_ON_OUTSIDE_OF_PIE: 'Outside Position',
		POSITION_TOP: 'Top',
		POSITION_RIGHT: 'Right',
		POSITION_LEFT: 'Left',
		POSITION_BOTTOM: 'Bottom',
		HORIZONTAL_ALIGNMENT: 'Alignment',
		HORIZONTAL_ALIGNMENT_AUTO: 'Auto',
		HORIZONTAL_ALIGNMENT_LEFT: 'Left',
		HORIZONTAL_ALIGNMENT_CENTER: 'Center',
		HORIZONTAL_ALIGNMENT_RIGHT: 'Right',
		VERTICAL_ALIGNMENT: 'Vertical Align',
		VERTICAL_ALIGNMENT_AUTO: 'Auto',
		VERTICAL_ALIGNMENT_TOP: 'Top',
		VERTICAL_ALIGNMENT_MIDDLE: 'Middle',
		VERTICAL_ALIGNMENT_BOTTOM: 'Bottom',
		TITLE_TEXT_ITEM_GAP: 'Gap of Titles',
		PADDING: 'Padding',
		SHOW: 'Show',
		SERIES_TEXT_SHOW: 'Show Series Text',
		SERIES_TEXT_POSITION: 'Series Text - Position',
		SERIES_TEXT_FONT: 'Series Text - Font',
		SERIES_TEXT_COLOR: 'Series Text - Color Rendering',
		SERIES_TEXT_BORDER: 'Series Text - Border Rendering',
		SERIES_TEXT_GAP_AND_PADDING: 'Series Text - Gap & Padding',
		LEGEND_ORIENT: 'Orient',
		LEGEND_ORIENT_HORIZONTAL: 'Horizontal',
		LEGEND_ORIENT_VERTICAL: 'Vertical',
		GRID_CONTAIN_LABEL: 'Contain Label',
		AXIS_TYPE: 'Axis Type',
		AXIS_TYPE_CATEGORY: 'Category',
		AXIS_TYPE_VALUE: 'Value',
		AXIS_TYPE_TIME: 'Time',
		AXIS_AUTO_MIN: 'Min Auto',
		AXIS_MIN: 'Min',
		AXIS_AUTO_MAX: 'Max Auto',
		AXIS_MAX: 'Max',
		AXIS_NAME_LOCATION: 'Location',
		AXIS_NAME_LOCATION_START: 'Start',
		AXIS_NAME_LOCATION_CENTER: 'Center',
		AXIS_NAME_LOCATION_END: 'End',
		AXIS_NAME_GAP: 'Gap',
		AXIS_NAME_ROTATE: 'Rotate',
		AXIS_LABEL_INSIDE: 'Inside',
		LABEL_GAP: 'Gap',
		LABEL_ROTATE: 'Rotate',
		LABEL_FORMAT_USING_GROUP: 'Using Group',
		LABEL_FORMAT_USING_PERCENTAGE: 'Using %',
		LABEL_VALUE_AS_PERCENTAGE: 'Value as %',
		LABEL_FRACTION_DIGITS: 'Fraction Digits',
		LABEL_POSITION_TOP: 'Top',
		LABEL_POSITION_LEFT: 'Left',
		LABEL_POSITION_RIGHT: 'Right',
		LABEL_POSITION_BOTTOM: 'Bottom',
		LABEL_POSITION_INSIDE: 'Inside',
		LABEL_POSITION_INSIDE_LEFT: 'Inside Left',
		LABEL_POSITION_INSIDE_RIGHT: 'Inside Right',
		LABEL_POSITION_INSIDE_TOP: 'Inside Top',
		LABEL_POSITION_INSIDE_BOTTOM: 'Inside Bottom',
		LABEL_POSITION_INSIDE_TOP_LEFT: 'Inside Top Left',
		LABEL_POSITION_INSIDE_BOTTOM_LEFT: 'Inside Bottom Left',
		LABEL_POSITION_INSIDE_TOP_RIGHT: 'Inside Top Right',
		LABEL_POSITION_INSIDE_BOTTOM_RIGHT: 'Inside Bottom Right',
		LABEL_POSITION_OUTSIDE: 'Outside',
		LABEL_POSITION_CENTER: 'Center',
		DECAL: 'Decal',
		ROAM: 'Roam',
		TREE_LAYOUT: 'Layout',
		TREE_LAYOUT_ORTHOGONAL: 'Orthogonal',
		TREE_LAYOUT_RADIAL: 'Radial',
		TREE_ORIENT: 'Orient',
		TREE_ORIENT_LEFT_RIGHT: 'Left to Right',
		TREE_ORIENT_RIGHT_LEFT: 'Right to Left',
		TREE_ORIENT_TOP_BOTTOM: 'Top to Bottom',
		TREE_ORIENT_BOTTOM_TOP: 'Bottom to Top',
		PIE_CENTER_X: 'Center X',
		PIE_CENTER_Y: 'Center Y',
		PIE_INSIDE_RADIUS: 'Inside Radius',
		PIE_OUTSIDE_RADIUS: 'Outside Radius',
		PIE_ROSE_TYPE: 'Rose Type',
		PIE_ROSE_TYPE_NONE: 'None',
		PIE_ROSE_TYPE_RADIUS: 'By Radius',
		PIE_ROSE_TYPE_AREA: 'By Area',
		PIE_SHOW_PERCENTAGE: 'Show %',
		PIE_LABEL_ALIGN_TO_NONE: 'None',
		PIE_LABEL_ALIGN_TO_LABEL_LINE: 'Label Line',
		PIE_LABEL_ALIGN_TO_EDGE: 'Edge',
		MAP_REGION: 'Region',
		MAP_REGION_CHINA_L1: 'China (Province)',
		MAP_REGION_CYPRUS_L1: 'Cyprus',
		MAP_REGION_JAPAN_L1: 'Japan (Prefecture)',
		MAP_REGION_SINGAPORE_L1: 'Singapore',
		MAP_REGION_USA_L1: 'United States (State)',
		TRUNCATION_TYPE: 'Type',
		TRUNCATION_NONE: 'None',
		TRUNCATION_TOP: 'Top',
		TRUNCATION_BOTTOM: 'Bottom',
		TRUNCATION_COUNT: 'Count',
		BAR_TRANSFORM_AXIS: 'Transform Axis',
		LINE_SMOOTH: 'Smooth',
		TOOLBOX_ORIENT: 'Orient',
		TOOLBOX_ORIENT_HORIZONTAL: 'Horizontal',
		TOOLBOX_ORIENT_VERTICAL: 'Vertical',
		SECTION_TITLE_COUNT_CHART: 'Count Style',
		SECTION_TITLE_BAR_CHART: 'Bar/Line style',
		SECTION_TITLE_PIE_CHART: 'Pie Style',
		SECTION_TITLE_TREE_CHART: 'Tree Style',
		SECTION_TITLE_TREEMAP_CHART: 'Treemap Style',
		SECTION_TITLE_MAP_CHART: 'Map Style',
		SECTION_TITLE_ECHART_TITLE: 'Title',
		SECTION_TITLE_ECHART_SUBTITLE: 'Subtitle',
		SECTION_TITLE_ECHART_LEGEND: 'Legend',
		SECTION_TITLE_ECHART_GRID: 'Grid',
		SECTION_TITLE_ECHART_XAXIS: 'X-Axis',
		SECTION_TITLE_ECHART_XAXIS_RANGE: 'X-Axis Range',
		SECTION_TITLE_ECHART_XAXIS_NAME: 'X-Axis Name',
		SECTION_TITLE_ECHART_XAXIS_NAME_FONT: 'X-Axis Name - Font',
		SECTION_TITLE_ECHART_XAXIS_NAME_COLOR: 'X-Axis Name - Color Rendering',
		SECTION_TITLE_ECHART_XAXIS_NAME_BORDER: 'X-Axis Name - Border Rendering',
		SECTION_TITLE_ECHART_XAXIS_NAME_GAP_AND_PADDING: 'X-Axis Name - Gap & Padding',
		SECTION_TITLE_ECHART_XAXIS_LABEL: 'X-Axis Label',
		SECTION_TITLE_ECHART_XAXIS_LABEL_FONT: 'X-Axis Label - Font',
		SECTION_TITLE_ECHART_XAXIS_LABEL_COLOR: 'X-Axis Label - Color Rendering',
		SECTION_TITLE_ECHART_XAXIS_LABEL_BORDER: 'X-Axis Label - Border Rendering',
		SECTION_TITLE_ECHART_XAXIS_LABEL_GAP_AND_PADDING: 'X-Axis Label - Gap & Padding',
		SECTION_TITLE_ECHART_XAXIS_LINE: 'X-Axis Split Line',
		SECTION_TITLE_ECHART_XAXIS_MINOR_LINE: 'X-Axis Minor Split Line',
		SECTION_TITLE_ECHART_YAXIS: 'Y-Axis',
		SECTION_TITLE_ECHART_YAXIS_RANGE: 'Y-Axis Range',
		SECTION_TITLE_ECHART_YAXIS_NAME: 'Y-Axis Name',
		SECTION_TITLE_ECHART_YAXIS_NAME_FONT: 'Y-Axis Name - Font',
		SECTION_TITLE_ECHART_YAXIS_NAME_COLOR: 'Y-Axis Name - Color Rendering',
		SECTION_TITLE_ECHART_YAXIS_NAME_BORDER: 'Y-Axis Name - Border Rendering',
		SECTION_TITLE_ECHART_YAXIS_NAME_GAP_AND_PADDING: 'Y-Axis Name - Gap & Padding',
		SECTION_TITLE_ECHART_YAXIS_LABEL: 'Y-Axis Label',
		SECTION_TITLE_ECHART_YAXIS_LABEL_FONT: 'Y-Axis Label - Font',
		SECTION_TITLE_ECHART_YAXIS_LABEL_COLOR: 'Y-Axis Label - Color Rendering',
		SECTION_TITLE_ECHART_YAXIS_LABEL_BORDER: 'Y-Axis Label - Border Rendering',
		SECTION_TITLE_ECHART_YAXIS_LABEL_GAP_AND_PADDING: 'Y-Axis Label - Gap & Padding',
		SECTION_TITLE_ECHART_YAXIS_LINE: 'Y-Axis Split Line',
		SECTION_TITLE_ECHART_YAXIS_MINOR_LINE: 'Y-Axis Minor Split Line',
		SECTION_TITLE_ECHART_SCRIPT: 'Script',
		SECTION_TITLE_ECHART_SCRIPT_VARS_DEF: 'Variables Defs',
		SECTION_TITLE_ECHART_SCRIPT_VARS: 'Variables',
		SECTION_TITLE_TRUNCATION: 'Data Truncation',
		SECTION_TITLE_TOOLBOX: 'Toolbox',
		NONAME_COLUMN: 'Noname Column',
		UNKNOWN_COLUMN_NAME: 'Unknown Column',
		CAN_NOT_DELETE_DIMENSION: 'Cannot delete this because of reach minimum dimension(s).',
		CAN_NOT_ADD_DIMENSION: 'Cannot add more because of reach maximum dimension(s).',
		CAN_NOT_DELETE_INDICATOR: 'Cannot delete this because of reach minimum indicator(s).',
		CAN_NOT_ADD_INDICATOR: 'Cannot add more because of reach maximum indicator(s).',
		ADD_DIMENSION: 'Add Dimension',
		ADD_INDICATOR: 'Add Indicator',
		PLEASE_SELECT_DIMENSION: 'Please select...',
		PLEASE_SELECT_INDICATOR: 'Please select...',
		PLEASE_SELECT_FUNNEL_VALUE: 'Please select...',
		NO_ENUM_FUNNEL_VALUE: 'No value selected',
		NO_SCRIPT_VARS: 'No script variables defined.',
		ARITHMETIC_NONE: 'As Is',
		ARITHMETIC_SUMMARY: 'Sum',
		ARITHMETIC_AVERAGE: 'Avg',
		ARITHMETIC_COUNT: 'Count',
		ARITHMETIC_MAX: 'Max',
		ARITHMETIC_MIN: 'Min',
		TYPE: 'Type',
		TYPES: {
			COUNT: 'Single Value',
			BAR: 'Bar',
			LINE: 'Line',
			SCATTER: 'Scatter',
			PIE: 'Pie',
			DOUGHNUT: 'Doughnut',
			NIGHTINGALE: 'Nightingale',
			SUNBURST: 'Sunburst',
			TREE: 'Tree',
			TREEMAP: 'Treemap',
			MAP: 'Map',
			CUSTOMIZED: 'Customized'
		},
		COUNT: {
			FORMAT_USING_GROUP: 'Using Group'
		},
		ECHART: {
			TEXT: 'Text'
		},
		DEFINITION_BROKEN: 'Definition not complete, cannot render chart for now.'
	},
	MEASURE_METHOD: {
		CONTINENT: 'Continent',
		REGION: 'Region',
		COUNTRY: 'Country',
		PROVINCE: 'Province',
		CITY: 'City',
		DISTRICT: 'District',
		FLOOR: 'Floor',
		RESIDENCE_TYPE: 'Residence Type',
		RESIDENTIAL_AREA: 'Residence Area',
		YEAR: 'Year',
		HALF_YEAR: '1st/2nd Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: '1st/2nd Half Month',
		TEN_DAYS: '1st/2nd/3rd Ten Days',
		WEEK_OF_YEAR: 'Week of Year',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: '1st/2nd Half Week',
		DAY_OF_MONTH: 'Day of Month',
		DAY_OF_WEEK: 'Day of Week',
		DAY_KIND: 'Workday/Weekday/Holiday',
		HOUR: 'Hour',
		HOUR_KIND: 'Work Time/Off Hours/Sleep Time',
		AM_PM: 'AM/PM',
		GENDER: 'Gender',
		OCCUPATION: 'Occupation',
		AGE: 'Age',
		RELIGION: 'Religion',
		NATIONALITY: 'Nationality',
		BIZ_TRADE: 'Business Trade',
		BIZ_SCALE: 'Business Scale',
		BOOLEAN: 'Boolean',
		ENUM: 'Enumeration'
	},
	RELEVANT_INDICATOR_TYPE: {
		SAME: 'Same',
		HIGH_CORRELATED: 'High Correlated',
		WEAK_CORRELATED: 'Weak Correlated',
		THIS_CAUSES_RELEVANT: 'Causes Relevant',
		RELEVANT_CAUSES_THIS: 'Caused by Relevant'
	},
	SHARE: {
		NOTHING: 'No content can be found, contact administrator for more information.'
	},
	CALENDAR: {
		JAN: 'January',
		FEB: 'February',
		MAR: 'March',
		APR: 'April',
		MAY: 'May',
		JUN: 'June',
		JUL: 'July',
		AUG: 'August',
		SEP: 'September',
		OCT: 'October',
		NOV: 'November',
		DEC: 'December',
		WEEK: 'Week',
		WEEK_0: '0th Week (Incomplete week)',
		WEEK_1: '1st Week',
		WEEK_2: '2nd Week',
		WEEK_3: '3rd Week',
		WEEK_4: '4th Week',
		WEEK_5: '5th Week (Incomplete week)',
		HALF_YEAR_1ST: '1st Half Year',
		HALF_YEAR_2ND: '2nd Half Year',
		QUARTER_1ST: '1st Quarter',
		QUARTER_2ND: '2nd Quarter',
		QUARTER_3RD: '3rd Quarter',
		QUARTER_4TH: '4th Quarter',
		HALF_MONTH_1ST: '1st Half Month',
		HALF_MONTH_2ND: '2nd Half Month',
		TEN_DAYS_1ST: '1st Ten Days Of Month',
		TEN_DAYS_2ND: '2nd Ten Days Of Month',
		TEN_DAYS_3RD: '3rd Ten Days Of Month',
		HALF_WEEK_1ST: '1st Half Week',
		HALF_WEEK_2ND: '2nd Half Week',
		SUNDAY: 'Sunday',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
		WORKDAY: 'Workday',
		WEEKEND: 'Weekend',
		HOLIDAY: 'Holiday',
		WORK_TIME: 'Work Time',
		OFF_HOURS: 'Off Hours',
		SLEEPING_TIME: 'Sleeping Time',
		AM: 'AM',
		PM: 'PM'
	},
	CONSANGUINITY: {
		OBJECTIVE_TARGET_BLOCK_LABEL: 'Targets',
		OBJECTIVE_FACTOR_BLOCK_LABEL: 'Measure Indicators',
		INDICATOR_BLOCK_LABEL: 'Indicators',
		SUBJECT_BLOCK_LABEL: 'Subjects',
		REPORT_BLOCK_LABEL: 'Reports',
		TOPIC_BLOCK_LABEL: 'Topics',
		BUCKET_BLOCK_LABEL: 'Buckets',
		ENUM_BLOCK_LABEL: 'Enums',
		NO_OBJECTIVE_TARGET_FOUND: 'No objective target found.',
		NONAME_OBJECTIVE_TARGET: 'Noname Objective Target',
		NONAME_OBJECTIVE_FACTOR: 'Noname Measure Indicator',
		NONAME_INDICATOR: 'Noname Indicator',
		NONAME_SUBJECT: 'Noname Subject',
		NONAME_SUBJECT_COLUMN: 'Noname Column',
		NONAME_REPORT: 'Noname Report',
		NONAME_TOPIC: 'Noname Topic',
		NONAME_TOPIC_FACTOR: 'Noname Topic Factor',
		NONAME_BUCKET: 'Noname Bucket',
		NONAME_ENUM: 'Noname Enum'
	}
};
