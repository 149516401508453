import React from 'react';
// a line placeholder to keep the line number is same as other language file

export const En = {
	$$settings: {
		code: 'en',
		name: 'English',
		fallback: ''
	},
	// fix key, anything under PLAIN will not be proxy to other type
	// anything must be string, should be placed here.
	// such as input tooltip(string property), tuple name(not for react component)
	PLAIN: {
		CONSOLE_SEARCH_PLACEHOLDER: 'Search connected space, subject, report or dashboard.',
		NEW_DASHBOARD_NAME: 'Dashboard',
		DEFAULT_DASHBOARD_NAME: 'Dashboard',
		NEW_CONNECTED_SPACE_NAME: 'Connected Space',
		DEFAULT_CONNECTED_SPACE_NAME: 'Connected Space',
		NEW_SUBJECT_NAME: 'Subject',
		DEFAULT_SUBJECT_NAME: 'Subject',
		NEW_REPORT_NAME: 'Report',
		DEFAULT_REPORT_NAME: 'Report',
		CONSTANT_INPUT_PLACEHOLDER: 'Constant value please...',
		UNKNOWN_TOPIC_NAME: 'Unknown Topic',
		UNKNOWN_FACTOR_NAME: 'Unknown Factor',
		REPORT_DESCRIPTION_PLACEHOLDER: 'Description here...',
		LOADING: 'Loading...',
		FIND_INDICATOR_PLACEHOLDER: 'Find by indicator name, description',
		FIND_TOPIC_OR_FACTOR_OR_SUBJECT_PLACEHOLDER: 'Find by topic name, factor name, subject name.',
		INDICATOR_NAME_PLACEHOLDER: 'A human reading name for indicator.',
		INDICATOR_DESCRIPTION_PLACEHOLDER: 'Indicator description here...',
		FIND_BUCKET_PLACEHOLDER: 'Search by bucket name, description.',
		BUCKET_CATEGORY_SEGMENT_VALUE_PLACEHOLDER: 'Key in category value here, confirm to add into segment.',
		FIND_INDICATOR_VALUE_BUCKETS_PLACEHOLDER: 'Search by bucket name.',
		FIND_OBJECTIVE_PLACEHOLDER: 'Find by objective name, description',
		FIND_CONVERGENCE_PLACEHOLDER: 'Find by convergence name, description',
		OBJECTIVE_NAME_PLACEHOLDER: 'A human reading name for objective.',
		OBJECTIVE_DESCRIPTION_PLACEHOLDER: 'Objective description here...',
		OBJECTIVE_VARIABLE_NAME_PLACEHOLDER: 'Variable name.',
		OBJECTIVE_FACTOR_NAME_PLACEHOLDER: 'Indicator name.',
		OBJECTIVE_FORMULA_CONSTANT_PLACEHOLDER: 'Use "{}" to include variables.',
		OBJECTIVE_FACTOR_FILTER_CONSTANT_PLACEHOLDER: 'Use "{}" to include variables.',
		SUBSCRIBE_MAIL_ADDRESS_PLACEHOLDER: '";" to separate multiple addresses.',
		NEW_DERIVED_OBJECTIVE_NAME: 'Objective',
		DEFAULT_DERIVED_OBJECTIVE_NAME: 'Objective',
		NEW_DERIVED_OBJECTIVE_TARGET_BREAKDOWN_NAME: 'Breakdown',
		CONVERGENCE_NAME_PLACEHOLDER: 'A human reading name for convergence.',
		CONVERGENCE_DESCRIPTION_PLACEHOLDER: 'Convergence description here...',
		CONVERGENCE_FREEWALK_VALUE_PLACEHOLDER: 'Split by ";"...'
	},
	STANDARD: {
		YES: 'Yes',
		NO: 'No'
	},
	ERROR: {
		UNAUTHORIZED: 'Unauthorized now, login please.',
		ACCESS_DENIED: 'Access denied, contact administrator for more information.',
		UNPREDICTED: 'Unpredicted error occurred, contact administrator for more information.'
	},
	ALERT: {
		BUTTON: 'Got It',
		NOT_IMPLEMENT: 'Not implemented yet.'
	},
	DIALOG: {
		BUTTON_YES: 'Yes',
		BUTTON_NO: 'No'
	},
	ACTIONS: {
		SAVE: 'Save',
		COPY: 'Copy',
		CLOSE: 'Close',
		CANCEL: 'Cancel',
		DELETE: 'Delete',
		REMOVE: 'Remove',
		CONFIRM: 'Confirm',
		NEXT: 'Next',
		SORT_ASC: 'Sort Ascending',
		SORT_DESC: 'Sort Descending',
		PREVIOUS_PAGE: 'Previous Page',
		NEXT_PAGE: 'Next Page',
		EXPAND: 'Expand',
		COLLAPSE: 'Collapse',
		MINIMIZE: 'Minimize',
		MAXIMIZE: 'Maximize',
		RESTORE: 'Restore',
		SUBSCRIBE: 'Subscribe'
	},
	LOGIN: {
		PRODUCT_TITLE: <>Indexical Metrics <span>&</span> Measure Advisory</>,
		MORNING: 'Good morning !',
		AFTERNOON: 'Good afternoon !',
		EVENING: 'Good evening !',
		BUTTON: 'Go !',
		NAME_EMPTY: 'Please tell me who you are, my friend.',
		CREDENTIAL_EMPTY: 'Credential is required to enjoy the journey.',
		FAIL: 'Name or credential cannot be identified now.'
	},
	ADMIN: {
		UNKNOWN_USER_GROUP: 'Unknown User Group',
		NONAME_USER_GROUP: 'Noname User Group'
	},
	DATASET: {
		UNFIX_COLUMN: 'Unfix Me and Follows',
		FIX_COLUMN: 'Fix Columns to Here',
		COMPRESS_COLUMNS: 'Compress Columns',
		DOWNLOAD_PAGE: 'Download This Page',
		DOWNLOAD_ALL: 'Download All',
		SIMULATE_DATA: 'Simulate',
		UPLOAD_DATA: 'Upload Data',
		UPLOAD_DATA_FAILURE: 'Failed to import dataset, check file format please.',
		DOWNLOAD_TEMPLATE: 'Download Template'
	},
	CONSOLE: {
		LOADING: 'Loading Personal Data...',
		BYE: 'Bye-bye now?',
		ERROR: {
			DASHBOARD_NOT_FOUND: 'Given dashboard not found.',
			CONNECTED_SPACE_NOT_FOUND: 'Given connected space not found.',
			DERIVED_OBJECTIVE_NOT_FOUND: 'Objective not found.',
			SUBJECT_NOT_FOUND: 'Given subject not found.',
			REPORT_NOT_FOUND: 'Given report not found.'
		},
		MENU: {
			TITLE: 'Watchmen Console',
			HOME: 'Home',
			DASHBOARDS: 'Dashboards',
			FAVORITE: 'Show Favorites',
			CONNECT_SPACE: 'Connect Space',
			SETTINGS: 'Settings',
			TO_CONSOLE: 'To Console',
			TO_ADMIN: 'To Admin',
			TO_DATA_QUALITY: 'To Data Quality Center',
			TO_INDICATOR: 'To Indicator',
			LOGOUT: 'Logout'
		},
		FAVORITE: {
			TITLE: 'My Favorite',
			NO_DATA: 'No favorite items.',
			PIN: 'Pin',
			UNPIN: 'Unpin'
		},
		HOME: {
			TITLE: 'Home',
			SORT_BY_NAME: 'Sort by Name',
			SORT_BY_VISIT_TIME: 'Sort by Visit Time',
			VIEW_COLLAPSE: 'Collapse',
			VIEW_ALL: 'View All',
			CONNECTED_SPACE_TITLE: 'Recent Connected Spaces',
			CREATE_CONNECTED_SPACE_BUTTON: 'Connect Space',
			DERIVED_OBJECTIVE_TITLE: 'Recent Objectives',
			CREATE_DERIVED_OBJECTIVE_BUTTON: 'Create Objective',
			DASHBOARD_TITLE: 'Recent Dashboards',
			CREATE_DASHBOARD_BUTTON: 'Create Dashboard',
			NO_RECENT: 'No recent used.'
		},
		DASHBOARD: {
			SHARE: 'Share',
			PRINT: 'Print',
			REFRESH: 'Refresh',
			AUTO_REFRESH: 'Auto Refresh (5 Minutes)',
			ADD_INTO_FAVORITE: 'Add into Favorite',
			REMOVE_FROM_FAVORITE: 'Remove from Favorite',
			ADD_REPORT: 'Add Report',
			ADD_PARAGRAPH: 'Add Paragraph',
			ADD_DASHBOARD: 'Add Dashboard',
			SWITCH_DASHBOARD: 'Switch Dashboard',
			DELETE_ME: 'Delete Me',
			SHARE_DIALOG_LABEL: 'Copy following url, and share to where you want.',
			URL_COPIED: 'URL copied.',
			DELETE_DIALOG_LABEL: 'Are you sure to delete dashboard? Please note that deletion cannot be recovered.',
			NO_MORE_DASHBOARD: 'No more dashboard exists.',
			SWITCH_DIALOG_LABEL: 'Please select dashboard',
			NO_MORE_REPORT: 'No more report exists.',
			DELETE_REPORT_DIALOG_LABEL: 'Are you sure to delete report? Please note that deletion cannot be recovered.',
			DELETE_PARAGRAPH_DIALOG_LABEL: 'Are you sure to delete paragraph? Please note that deletion cannot be recovered.',
			NO_REPORT: 'No report defined.',
			SHOW_PRINT_PAGE: 'Show Page Size',
			HIDE_PRINT_PAGE: 'Hide Page Size',
			SET_AS_ADMIN_HOME: 'Set as Admin Home',
			FUNNEL_TITLE: 'Funnels'
		},
		CONNECTED_SPACE: {
			CATALOG: 'Catalog',
			SUBJECT_DEF: 'Definition',
			SUBJECT_DATA: 'Dataset',
			SUBJECT_REPORTS: 'Reports',
			SUBJECT_REPORT: 'Report',
			REPORT_DATA: 'Dataset',
			REPORT_FILTER: 'Filter',
			REPORT_FUNNEL: 'Funnel',
			SHARE: 'Share',
			ADD_REPORT: 'Add Report',
			ADD_SUBJECT: 'Add Subject',
			OPEN_SUBJECT: 'Open Subject',
			ADD_CONNECTED_SPACE: 'Connect Space',
			SWITCH_CONNECTED_SPACE: 'Switch Connected Space',
			DELETE_ME: 'Delete Me',
			ADD_INTO_FAVORITE: 'Add into Favorite',
			REMOVE_FROM_FAVORITE: 'Remove from Favorite',
			SET_AS_TEMPLATE: 'Set as Template',
			REMOVE_FROM_TEMPLATE: 'Remove from Template',
			DELETE_DIALOG_LABEL: 'Are you sure to delete connected space? Please note that deletion cannot be recovered.',
			NO_MORE_CONNECTED_SPACE: 'No more connected space exists.',
			SWITCH_DIALOG_LABEL: 'Please select connected space',
			CREATE_DIALOG_LABEL: 'Please select available space',
			CREATE_DIALOG_CHOOSE_TEMPLATE_LABEL: 'Please select favorite templates',
			TEMPLATE: 'Template',
			TEMPLATE_CREATE_BY: 'Created By',
			NO_MORE_SPACE: 'No space exists, contact your administrator for more information.',
			SPACE_NOT_FOUND: 'Space definition not found, contact your administrator for more information.',
			TOPICS_NOT_FOUND: 'Topics definition not found, contact your administrator for more information.',
			TOPICS_COUNT_MISMATCH: 'Topics count mismatch, contact your administrator for more information.',
			TOPIC_WITH_NO_FACTOR: 'No factor defined, contact your administrator for more information.',
			DELETE_SUBJECT_DIALOG_LABEL: 'Are you sure to delete subject? Please note that deletion cannot be recovered.',
			SWITCH_SUBJECT: 'Switch Subject',
			SWITCH_SUBJECT_DIALOG_LABEL: 'Please select subject',
			NO_MORE_SUBJECT: 'No more subject exists.',
			SUBJECT_DEF_INVALID: 'Something incorrect in subject definition, please correct it first.',
			SUBJECT_PICK_TOPICS: 'Pick Topics',
			SUBJECT_DEFINE_COLUMNS: 'Define Columns',
			SUBJECT_FILTER_DATA: 'Filter Data',
			SUBJECT_SET_JOINS: 'Set Joins',
			SUBJECT_DEF_OVERVIEW: 'Overview',
			NO_PICKED_TOPIC_FOR_SUBJECT: 'No topic picked.',
			NO_DATASET_COLUMN_FOR_SUBJECT: 'No columns defined, do you want to ',
			CREATE_DATASET_COLUMN_WHEN_NONE: 'create one',
			NO_DATASET_COLUMN_FOR_SUBJECT_2: '?',
			ALIAS: 'As',
			SUBJECT_COLUMN_ARITHMETIC: 'Aggregate On',
			SUBJECT_COLUMN_ARITHMETIC_NONE: 'As Is',
			SUBJECT_COLUMN_ARITHMETIC_COUNT: 'Count',
			SUBJECT_COLUMN_ARITHMETIC_DISTINCT_COUNT: 'Distinct Count',
			SUBJECT_COLUMN_ARITHMETIC_SUMMARY: 'Sum',
			SUBJECT_COLUMN_ARITHMETIC_AVERAGE: 'Avg',
			SUBJECT_COLUMN_ARITHMETIC_MAX: 'Max',
			SUBJECT_COLUMN_ARITHMETIC_MIN: 'Min',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT: 'Alignment',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_LEFT: 'Left',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_CENTER: 'Center',
			SUBJECT_COLUMN_RENDERER_ALIGNMENT_RIGHT: 'Right',
			SUBJECT_COLUMN_RENDERER_FORMAT: 'Format',
			SUBJECT_COLUMN_RENDERER_FORMAT_NONE: 'As Is',
			SUBJECT_COLUMN_RENDERER_HIGHLIGHT_NEGATIVE: 'Highlight Negative',
			SUBJECT_COLUMN_POSITION_ALREADY_AT_FIRST: 'Cannot move up because already on top.',
			SUBJECT_COLUMN_POSITION_ALREADY_AT_LAST: 'Cannot move down because already on bottom.',
			ADD_SUBJECT_COLUMN: 'Add Column',
			ADD_SUBJECT_RECALCULATE_COLUMN: 'Add Recalculate Column',
			CAN_NOT_DELETE_CHILD_FROM_COMPUTED: 'Cannot delete this because of reach minimum parameter(s).',
			CAN_NOT_ADD_CHILD_INTO_COMPUTED: 'Cannot add more because of reach maximum parameter(s).',
			ADD_COMPUTE_PARAMETER: 'Add Parameter',
			NO_DATASET_FILTER_FOR_SUBJECT: 'No filter defined, do you want to ',
			CREATE_DATASET_FILTER_WHEN_NONE: 'create one',
			NO_DATASET_FILTER_FOR_SUBJECT_2: '?',
			ADD_SUB_EXPRESSION_FILTER: 'Add Sub Expression',
			ADD_SUB_JOINT_FILTER: 'Add Sub Joint',
			NO_DATASET_JOIN_FOR_SUBJECT: 'No join defined, do you want to ',
			CREATE_DATASET_JOIN_WHEN_NONE: 'create one',
			NO_DATASET_JOIN_FOR_SUBJECT_2: '?',
			ADD_SUBJECT_JOIN: 'Add Join',
			SUBJECT_SELECT: 'Select',
			SUBJECT_NO_SELECT: 'No column defined.',
			SUBJECT_FROM: 'From',
			SUBJECT_NO_FROM: 'No join defined.',
			SUBJECT_JOIN_INNER: 'Inner Join',
			SUBJECT_JOIN_LEFT: 'Left Join',
			SUBJECT_JOIN_RIGHT: 'Right Join',
			SUBJECT_JOIN_ON: 'On',
			SUBJECT_JOIN_EQUALS: '=',
			SUBJECT_JOIN_AND: 'And',
			SUBJECT_WHERE: 'Where',
			SUBJECT_NO_WHERE: 'No filter defined.',
			SUBJECT_UNKNOWN_PARAMETER: 'Unknown Parameter',
			SUBJECT_EMPTY_CONSTANT: 'Empty Constant Value',
			SUBJECT_COLUMN_AS: 'As',
			SUBJECT_NO_ALIAS: '?',
			SUBJECT_FILTER_JOINT_NO_STATEMENT: 'No Statement in Joint',
			SUBJECT_UNKNOWN_FILTER: 'Unknown Filter',
			NO_DATASET_COLUMN: 'No column defined.',
			NO_REPORT: 'No report defined, do you want to ',
			CREATE_REPORT: 'create one',
			NO_REPORT_2: '?',
			NO_MORE_REPORT: 'No more report exists.',
			OPEN_REPORT: 'Open Report',
			SWITCH_REPORT: 'Switch Report',
			SWITCH_REPORT_DIALOG_LABEL: 'Please select report',
			DELETE_REPORT_DIALOG_LABEL: 'Are you sure to delete report? Please note that deletion cannot be recovered.',
			COLLAPSE_REPORT_SETTINGS_SECTIONS: 'Collapse All Sections',
			EXPAND_REPORT_SETTINGS_SECTIONS: 'Expand All Sections',
			REPORT_NO_FILTER: 'No filter defined, do you want to ',
			CREATE_REPORT_FILTER: 'create now',
			REPORT_NO_FILTER_2: '?',
			REPORT_NO_FUNNEL: 'No funnel enabled.'
		},
		DERIVED_OBJECTIVE: {
			CREATE_DIALOG_CHOOSE_TEMPLATE_LABEL: 'Please select favorite objective',
			TEMPLATE: 'Objective',
			NO_MORE_OBJECTIVE: 'No objective exists, contact your administrator for more information.',
			NO_OBJECTIVE_SELECTED: 'No objective selected.',
			ADD_INTO_FAVORITE: 'Add into Favorite',
			REMOVE_FROM_FAVORITE: 'Remove from Favorite',
			ADD_DERIVED_OBJECTIVE: 'Create Objective',
			SWITCH_DERIVED_OBJECTIVE: 'Switch Objective',
			SWITCH_DIALOG_LABEL: 'Please select objective',
			DELETE_DIALOG_LABEL: 'Are you sure to delete objective? Please note that deletion cannot be recovered.',
			DELETE_ME: 'Delete Me',
			REFRESH_VALUES: 'Refresh',
			SHOW_VARIABLES: 'Show Variables',
			HIDE_VARIABLES: 'Hide Variables',
			UNKNOWN_TARGET_NAME: 'Unknown target',
			VARIABLES_TITLE: 'Criteria Variables',
			UNKNOWN_VARIABLE_NAME: 'Unknown variable',
			TIME_FRAME_TITLE: 'Time Frame',
			NOT_TIME_RELATED: 'Objective is not time related.',
			TIME_FRAME_KIND_YEAR: 'Yearly',
			TIME_FRAME_KIND_HALF_YEAR: 'Half Yearly',
			TIME_FRAME_KIND_QUARTER: 'Quarterly',
			TIME_FRAME_KIND_MONTH: 'Monthly',
			TIME_FRAME_KIND_WEEK_OF_YEAR: 'Weekly',
			TIME_FRAME_KIND_DAY_OF_MONTH: 'Daily, Of Month',
			TIME_FRAME_KIND_DAY_OF_WEEK: 'Daily, Of Week',
			TIME_FRAME_KIND_LAST_N_YEARS: 'Last N Years',
			TIME_FRAME_KIND_LAST_N_MONTHS: 'Last N Months',
			TIME_FRAME_KIND_LAST_N_WEEKS: 'Last N Weeks',
			TIME_FRAME_KIND_LAST_N_DAYS: 'Last N Days',
			BREAKDOWN: 'Breakdown',
			HIDE_BREAKDOWN: 'Hide Breakdown',
			ADD_BREAKDOWN: 'Add Breakdown',
			REMOVE_BREAKDOWN: 'Remove Breakdown',
			DIMENSION_ON_INDICATOR_VALUE: 'On Objective Value',
			ADD_FIRST_BREAKDOWN_DIMENSION: 'Add first dimension',
			ADD_BREAKDOWN_DIMENSION: 'Add dimension',
			VALUE_AS_DIMENSION: 'Using Naturally Classification',
			ASK_BREAKDOWN_VALUES: 'Get Values',
			BREAKDOWN_NO_DIMENSION: 'No dimension declared.',
			FAILED_ON_FETCH_BREAKDOWN_VALUES: 'Failed on get values.',
			NO_DATA_FETCHED_BREAKDOWN_VALUES: 'No data.',
			SHARE: 'Share',
			PRINT: 'Print',
			SHARE_DIALOG_LABEL: 'Copy following url, and share to where you want.',
			URL_COPIED: 'URL copied.'
		}
	},
	INDICATOR: {
		MENU: {
			TITLE: 'Watchmen Indicator',
			BUCKETS: 'Buckets',
			INDICATORS: 'Indicators',
			OBJECTIVES: 'Objectives',
			CONVERGENCES: 'Convergences',
			SETTINGS: 'Settings',
			LOGOUT: 'Logout'
		},
		ON_EDIT: 'Still in editing, all changes will be lost if interrupt. Are you sure to continue?',
		STEP: 'Step',
		INDICATOR: {
			LIST_TITLE: 'Indicators',
			LIST_CREATE_INDICATOR: 'Define Indicator',
			LIST_LABEL: 'Indicator',
			NEW_INDICATOR_PREFIX: 'A New',
			EXISTING_INDICATOR_PREFIX: 'An Existing',
			CREATE_AT: 'Created At',
			LAST_MODIFIED_AT: 'Last Modified At',
			TITLE: 'Define Indicator',
			WAIT_INPUT: 'Waiting for input...',
			SEARCHING: 'Searching...',
			NO_MATCHED: 'No matching data found.',
			OR: 'or',
			CREATE_INDICATOR: 'Define A New One',
			FIND_INDICATOR: 'Find Existing',
			DISCARD_FIND_INDICATOR: 'Discard Finding',
			ON_CREATE_INDICATOR: 'Defining An Indicator',
			ON_VIEW_INDICATOR: 'View Indicator',
			RESTART: 'Restart',
			BACK_TO_LIST: 'Back to list',
			PICK_TOPIC_OR_SUBJECT: 'Pick a Topic or Subject',
			DEFINE_ON_TOPIC: 'Define on Topic',
			DEFINE_ON_SUBJECT: 'Define on Subject',
			INDICATOR_ON_TOPIC: 'On Topic',
			INDICATOR_ON_SUBJECT: 'On Subject',
			FAILED_TO_LOAD_INDICATOR: 'Failed to load indicator, contact your administrator for more information.',
			AGGREGATE_ITEM_TITLE: 'Pick an aggregation function.',
			MEASURE_METHODS_TITLE: 'Available measures of current indicator were detected automatically.',
			FACTOR: 'Factor',
			FACTOR_NAME: 'Name',
			FACTOR_LABEL: 'Label',
			FACTOR_TYPE: 'Type',
			FACTOR_ENUM: 'Enumeration',
			GEO: 'GEO',
			TIME_PERIOD: 'Time Period',
			INDIVIDUAL: 'Individual',
			ORGANIZATION: 'Organization',
			CATEGORY: 'Category',
			DEFINE_BUCKETS_TITLE: 'Predefined buckets for better categorization and aggregation.',
			INDICATOR_VALUE_BUCKET_ONLY_ON_FACTOR_LABEL: 'Value bucketing only available on factor indicator',
			INDICATOR_VALUE_BUCKET_LABEL: 'Indicator value bucketing',
			LINK_INDICATOR_VALUE_BUCKETS: 'Link Predefined Value Buckets with Indicator Value',
			DISCARD_LINK_INDICATOR_VALUE_BUCKETS: 'Discard Link',
			MEASURE_BUCKET_LABEL: 'Measure bucketing',
			NO_MEASURE_BUCKET: 'No appropriate measure bucket found.',
			VIEW_MEASURE_BUCKETS: 'View Predefined Buckets on Measure',
			IGNORE_DEFINE_BUCKETS: 'Ignore Buckets Definition',
			CHANGE_NAME: 'Change Name',
			NOT_NOW: 'Not Now',
			SAVE_INDICATOR: 'Save Indicator',
			SAVE_NAME: 'Save Name',
			REQUIRE_INDICATOR_NAME: 'A human reading name for indicator is required.',
			RELEVANT_TITLE: 'Relevant indicators of current indicator can be detected automatically.',
			INDICATOR_NAME: 'Indicator',
			INDICATOR_RELEVANT_TYPE: 'Relevant Type',
			IRRELEVANT: 'Irrelevant',
			NO_RELEVANT_DETECTED: 'No relevant indicator detected yet.',
			DETECT_RELEVANT: 'Detect Potential Relevant Indicators',
			IGNORE_DETECT_RELEVANT: 'Ignore Detect Now',
			FILTERS_ON_TOPIC: 'Data restriction.',
			FILTERS_ON_SUBJECT: 'Data restriction already be defined on subject and space, will be inherited by indicator.',
			CATEGORIES_TITLE: 'Categories.',
			DESCRIPTION_TITLE: 'Description.',
			LAST_STEP_TITLE: 'Indicator is ready.',
			PREPARE_ANOTHER: 'Define Another One'
		},
		BUCKET: {
			TITLE: 'Buckets',
			LABEL: 'Bucket',
			NEW_BUCKET_PREFIX: 'A New',
			EXISTING_BUCKET_PREFIX: 'An Existing',
			CREATE_BUCKET: 'Create Bucket',
			NAME_LABEL: 'Bucket Name:',
			TYPE_LABEL: 'Bucket Type:',
			TYPE_IS_FIXED_ONCE_SAVE: 'Bucket type cannot be change once saved.',
			DESCRIPTION_LABEL: 'Description:',
			CREATE_AT: 'Created At',
			LAST_MODIFIED_AT: 'Last Modified At',
			BUCKET_NAME_IS_REQUIRED: 'A human reading name is required.',
			BUCKET_MEASURE_IS_REQUIRED: 'Please select the measure method.',
			BUCKET_ENUM_IS_REQUIRED: 'Please select an enumeration.',
			BUCKET_SEGMENTS_IS_REQUIRED: 'At least two segments should be defined.',
			BUCKET_SEGMENT_NAME_IS_REQUIRED: 'A human reading name of segment is required.',
			BE_NUMERIC_OF_NUMERIC_SEGMENT: 'Value should be a numeric.',
			EMPTY_FIRST_MIN_OF_NUMERIC_SEGMENT: 'Minimum value of first segment should be left as empty.',
			NOT_EMPTY_FIRST_MAX_OF_NUMERIC_SEGMENT: 'Maximum value of first segment should not be empty.',
			NOT_EMPTY_OF_NUMERIC_SEGMENT: 'Values of segment in-between should not be empty.',
			MIN_MAX_ORDER_OF_NUMERIC_SEGMENT: 'Minimum value should be less than maximum value.',
			NOT_EMPTY_LAST_MIN_OF_NUMERIC_SEGMENT: 'Minimum value of last segment should not be empty.',
			EMPTY_LAST_MAX_OF_NUMERIC_SEGMENT: 'Maximum value of last segment should be left as empty.',
			RANGE_LACK_EXISTS_OF_NUMERIC_SEGMENT: 'Range lack exists.',
			RANGE_OVERLAP_EXISTS_OF_NUMERIC_SEGMENT: 'Range overlap exists.',
			NOT_EMPTY_OF_CATEGORY_SEGMENT: 'At least one category should be defined.',
			NO_DUPLICATED_OF_CATEGORY_SEGMENT: 'Duplicated categories are not allowed in segment.',
			ONE_OTHERS_SEGMENT_OF_CATEGORY_SEGMENT: 'Only one segment can contains category "others".',
			ONE_OTHERS_VALUE_OF_CATEGORY_SEGMENT: 'Only one category "others" in segment.',
			NO_SHARED_OF_CATEGORY_SEGMENT: 'Same category in multiple segments is not allowed.',
			NO_SEGMENT_DEFINED: 'No Segment Defined',
			ONE_SEGMENT_DEFINED: '1 Segment',
			N_SEGMENT_DEFINED: 'Segments',
			BUCKET_TYPE_VALUE: 'Value',
			BUCKET_TYPE_VALUE_MEASURE: 'Value Measure',
			BUCKET_TYPE_CATEGORY_MEASURE: 'Category Measure',
			BUCKET_TYPE_ENUM_MEASURE: 'Enumeration Measure',
			RANGE_INCLUDING_LABEL: 'Value Inclusion Mode:',
			RANGE_INCLUDE_MIN: '[Include Min Value, Exclude Max Value)',
			RANGE_INCLUDE_MAX: '(Exclude Max Value, Include Max Value]',
			SEGMENTS_LABEL: 'Segments:',
			SEGMENT_NAME: 'Segment Name',
			VALUE_SEGMENT_MIN_LABEL: 'Minimum Value',
			VALUE_SEGMENT_MAX_LABEL: 'Maximum Value',
			ADD_SEGMENT: 'Add Segment',
			SORT_SEGMENTS: 'Sort Segments',
			MEASURE_METHOD_LABEL: 'Measure Method:',
			MEASURE_METHOD_IS_FIXED_ONCE_SAVE: 'Measure method cannot be change once saved.',
			CATEGORY_SEGMENT_LABEL: 'Category Value',
			DUPLICATE_CATEGORY_SEGMENT_VALUE: 'Category already exists in this segment.',
			DUPLICATE_CATEGORY_SEGMENT_VALUE_CASE_IGNORED: 'Category (case insensitive) already exists in this segment, do you want to add it anyway?',
			OTHERS_IS_EXCLUSIVE_ON_CATEGORY_SEGMENT: 'Category "others" is exclusive in segment, cannot be coexisting with other values.',
			NO_SEGMENT_VALUE_DEFINED: 'No Value Defined',
			ADD_OTHER_CATEGORY: 'Add Segment for Others',
			ENUM_LABEL: 'Enumeration:',
			ENUM_IS_FIXED_ONCE_SAVE: 'Enumeration cannot be changed once saved.',
			SORT_SEGMENTS_BY_CODE: 'Sort Segments by Code',
			SORT_SEGMENTS_BY_NAME: 'Sort Segments by Name',
			AVAILABLE_ENUM_ITEMS_LABEL: 'Available Enumeration Items:',
			NO_AVAILABLE_ENUM_ITEMS: 'No available items.',
			ADD_AVAILABLE_ITEMS_INTO_SEGMENT: 'Add Into Segment',
			SEGMENT_LABEL: 'Segment',
			PLEASE_SELECT_SEGMENT: 'Please pick a segment to include selected enumeration items.',
			PLEASE_SELECT_ENUM_ITEM: 'Please select at lease one enumeration item to add into segment.'
		},
		OBJECTIVE: {
			LIST_TITLE: 'Objectives',
			LIST_CREATE_OBJECTIVE: 'Define Objective',
			LIST_LABEL: 'Objective',
			NEW_OBJECTIVE_PREFIX: 'A New',
			EXISTING_OBJECTIVE_PREFIX: 'An Existing',
			CREATE_AT: 'Created At',
			LAST_MODIFIED_AT: 'Last Modified At',
			TITLE: 'Define Objective',
			FAILED_TO_LOAD_OBJECTIVE: 'Failed to load objective, contact your administrator for more information.',
			TARGETS_TITLE: 'Declare quantitative target values.',
			BACK_TO_LIST: 'Back to list',
			TARGET_NAME: 'Name:',
			TARGET_TOBE: 'To Be Value:',
			TARGET_TOBE_PLACEHOLDER: 'A numeric value, or a percentage value (ends with %).',
			TARGET_ASIS: 'As Is Value:',
			TARGET_ASIS_REFER: 'Refer',
			TARGET_ASIS_COMPUTED: 'Computed',
			TARGET_BETTER_SIDE: 'Better Value:',
			TARGET_BETTER_SIDE_MORE: 'More is better',
			TARGET_BETTER_SIDE_LESS: 'Less is better',
			TARGET_ASK_PREVIOUS_CYCLE: 'Include previous cycle (eg. month-on-month)?',
			TARGET_ASK_CHAIN_CYCLE: 'Include chain cycle (eg. year-on-year)?',
			TARGET_VALUES: 'Values:',
			TARGET_CURRENT_VALUE: 'Current',
			TARGET_PREVIOUS_VALUE: 'Previous',
			TARGET_CHAIN_VALUE: 'Chain',
			TARGET_FINISH_RATE: 'Rate',
			ADD_TARGET: 'Add New Target',
			TARGET_CONSANGUINITY: 'Consanguinity',
			TIME_FRAME_TITLE: 'Set a time frame.',
			TIME_FRAME_KIND: 'Kind:',
			TIME_FRAME_KIND_NONE: 'Not Time Frame Related',
			TIME_FRAME_KIND_YEAR: 'Year',
			TIME_FRAME_KIND_HALF_YEAR: 'Half Year',
			TIME_FRAME_KIND_QUARTER: 'Quarter',
			TIME_FRAME_KIND_MONTH: 'Month',
			TIME_FRAME_KIND_WEEK_OF_YEAR: 'Week',
			TIME_FRAME_KIND_DAY_OF_MONTH: 'Day, Of Month',
			TIME_FRAME_KIND_DAY_OF_WEEK: 'Day, Of Week',
			TIME_FRAME_KIND_LAST_N_YEARS: 'Last N Years',
			TIME_FRAME_KIND_LAST_N_MONTHS: 'Last N Months',
			TIME_FRAME_KIND_LAST_N_WEEKS: 'Last N Weeks',
			TIME_FRAME_KIND_LAST_N_DAYS: 'Last N Days',
			TIME_FRAME_N_IS: 'N is:',
			TIME_FRAME_TILL: 'Till:',
			TIME_FRAME_TILL_NOW: 'Now',
			TIME_FRAME_TILL_LAST_COMPLETE_CYCLE: 'Last Complete Cycle',
			TIME_FRAME_TILL_SPECIFIED: 'Specified',
			TIME_FRAME_TILL_SPECIFIED_AT: 'At:',
			TIME_FRAME_TILL_SPECIFIED_AT_DESC: 'It is last day of specified time frame, the frame is extrapolated forward from the specified day.',
			TIME_FRAME_CALCULATED: 'Current Frame:',
			TIME_FRAME_PREVIOUS_CYCLE: 'Previous Frame:',
			TIME_FRAME_CHAIN_CYCLE: 'Frame in Previous Cycle:',
			VARIABLES_TITLE: 'Variables.',
			VARIABLE_IS: 'is',
			VARIABLE_KIND_SINGLE_VALUE: 'Single Value',
			VARIABLE_KIND_RANGE: 'Range Values',
			VARIABLE_KIND_BUCKET: 'From Bucket',
			VARIABLE_RANGE_TO: '..',
			VARIABLE_RANGE_INCLUDE_MIN: '[',
			VARIABLE_RANGE_NOT_INCLUDE_MIN: '(',
			VARIABLE_RANGE_INCLUDE_MAX: ']',
			VARIABLE_RANGE_NOT_INCLUDE_MAX: ')',
			VARIABLE_BUCKET_PLACEHOLDER: 'A bucket...',
			VARIABLE_BUCKET_INCORRECT_SELECTED: 'Unknown bucket selected.',
			VARIABLE_BUCKET_NO_AVAILABLE: 'No available bucket.',
			VARIABLE_BUCKET_SEGMENT_PLACEHOLDER: 'A segment...',
			VARIABLE_BUCKET_SELECT_FIRST: 'Select bucket first.',
			VARIABLE_BUCKET_SEGMENT_INCORRECT_SELECTED: 'Unknown segment selected.',
			VARIABLE_BUCKET_SEGMENT_NO_AVAILABLE: 'No available segment.',
			ADD_VARIABLE: 'Add New Variable',
			REMOVE_VARIABLE: 'Remove Variable',
			FACTORS_TITLE: 'Measure by indicators.',
			FACTOR_INDICATOR: 'Indicator',
			FACTOR_INDICATOR_FILTER: 'Filter',
			FACTOR_FORMULA: 'Formula',
			FACTOR_REFER_SELF: 'Myself',
			REFER_INDICATOR_PLACEHOLDER: 'An indicator...',
			REFER_INDICATOR_BUT_INCORRECT: 'Unknown indicator',
			REFER_INDICATOR_BUT_NO_AVAILABLE: 'No available indicator.',
			REFER_INDICATOR_BUT_NOT_READY: 'is available only when indicator selected.',
			REFER_INDICATOR_ON_NO_FILTER: 'On all data',
			REFER_INDICATOR_ON_TOPIC_FILTER_PLACEHOLDER: 'A factor...',
			REFER_INDICATOR_ON_SUBJECT_FILTER_PLACEHOLDER: 'A column...',
			REFER_INDICATOR_FILTER_ON_INCORRECT_FACTOR_OR_COLUMN: 'Unknown selection',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_FACTOR_OR_COLUMN: 'No available candidate.',
			REFER_INDICATOR_FILTER_ON_INCORRECT_BUCKET: 'Unknown bucket',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_BUCKET: 'No available bucket.',
			REFER_INDICATOR_FILTER_BUCKET_PLACEHOLDER: 'A bucket...',
			REFER_INDICATOR_FILTER_BUCKET_FIRST: 'Select bucket first.',
			REFER_INDICATOR_FILTER_NO_AVAILABLE_BUCKET_SEGMENT: 'No available segment.',
			REFER_INDICATOR_FILTER_BUCKET_SEGMENT_PLACEHOLDER: 'A segment...',
			ADD_INDICATOR: 'Add Indicator',
			ADD_COMPUTED_INDICATOR: 'Add Computation',
			NOT_NOW: 'Not Now',
			SAVE_OBJECTIVE: 'Save Objective',
			SAVE_NAME: 'Save Name',
			REQUIRE_OBJECTIVE_NAME: 'A human reading name for objective is required.',
			CHANGE_NAME: 'Change Name',
			NAME_TITLE: 'Set a human reading name.',
			DESCRIPTION_TITLE: 'Description.',
			USER_GROUP_TITLE: 'Grant to user group.',
			GRANT_USER_GROUP: 'Grant Access',
			REFER_FACTOR_PLACEHOLDER: 'An indicator...',
			REFER_FACTOR_BUT_NONAME: 'Noname Indicator',
			REFER_FACTOR_BUT_INCORRECT: 'Unknown indicator',
			REFER_FACTOR_BUT_NO_AVAILABLE: 'No available indicator.',
			ADD_SUB_PARAMETER: 'Add Parameter',
			CANNOT_ADD_COMPUTATION_PARAMETER: 'Cannot add more because of reach maximum parameter(s).',
			CANNOT_DELETE_COMPUTATION_PARAMETER: 'Cannot delete this because of reach minimum parameter(s).',
			EXPRESSION: 'Expression',
			ADD_SUB_EXPRESSION: 'Add Sub Expression',
			ADD_SUB_JOINT: 'Add Sub Joint',
			TEST_FACTOR: 'Value',
			TEST_VALUE_CLICK: 'Click to retrieve values',
			TEST_VALUE_GET_NONE: 'No data retrieved from server side.'
		},
		CONVERGENCE: {
			LIST_TITLE: 'Convergences',
			LIST_CREATE_CONVERGENCE: 'Define Convergence',
			LIST_LABEL: 'Convergence',
			NEW_CONVERGENCE_PREFIX: 'A New',
			EXISTING_CONVERGENCE_PREFIX: 'An Existing',
			CREATE_AT: 'Created At',
			LAST_MODIFIED_AT: 'Last Modified At',
			FAILED_TO_LOAD_CONVERGENCE: 'Failed to load convergence, contact your administrator for more information.',
			TITLE: 'Define Convergence',
			BACK_TO_LIST: 'Back to list',
			DEF_TITLE: 'Definition.',
			VARIABLE_NAME: 'Name',
			VARIABLE_TYPE_FREE_WALK: 'Free walk',
			VARIABLE_FREE_WALK_SEGMENTS: 'Segments',
			VARIABLE_TYPE_BUCKET: 'Bucket',
			VARIABLE_BUCKET: 'Bucket',
			VARIABLE_BUCKET_SEGMENTS: 'Segments',
			VARIABLE_TYPE_TIMEFRAME: 'Timeframe',
			VARIABLE_TIMEFRAME_KIND: 'Frequency',
			VARIABLE_TIMEFRAME_TILL: 'Till',
			VARIABLE_TIMEFRAME_TIMES: 'Times',
			VARIABLE_TIMEFRAME_KIND_DAY: 'Daily',
			VARIABLE_TIMEFRAME_KIND_WEEK: 'Weekly',
			VARIABLE_TIMEFRAME_KIND_MONTH: 'Monthly',
			VARIABLE_TIMEFRAME_KIND_QUARTER: 'Quarterly',
			VARIABLE_TIMEFRAME_KIND_HALFYEAR: 'Half Yearly',
			VARIABLE_TIMEFRAME_KIND_YEAR: 'Annually',
			X_AXIS_ADD_PLACEHOLDER: 'Add legend into X-Axis',
			Y_AXIS_ADD_PLACEHOLDER: 'Add legend into Y-Axis',
			PLEASE_SELECT_TARGET: 'Please select...',
			TARGET_USE_TIME_FRAME: 'Use Time Frame?',
			VARIABLE_MAPPING_IGNORED: 'Ignored',
			VARIABLE_MAPPING_SOURCE_PLACEHOLDER: 'Please select...',
			FREEZE_DEF: 'Freeze Legends',
			UNFREEZE_DEF: 'Unfreeze Legends',
			VARIABLE_NOT_FILLED: 'Please confirm that all attribute values for the defined axis legend have been correctly filled in.',
			ASK_VALUES: 'Definition Accomplished, Ask Data',
			BACK_TO_DEF: 'Back to Definition',
			CHANGE_NAME: 'Change Name',
			NAME_TITLE: 'Set a human reading name.',
			DESCRIPTION_TITLE: 'Description.',
			USER_GROUP_TITLE: 'Grant to user group.',
			GRANT_USER_GROUP: 'Grant Access'
		}
	},
	SUBSCRIBE: {
		ON: 'Subscribe via email.',
		BY_MAIL: 'Email',
		MAIL_ADDRESS: 'Mail Address',
		BY_SLACK: 'Slack',
		SLACK_CHANNEL: 'Slack Channel',
		FREQUENCY: 'Frequency',
		DAILY: 'Daily',
		WEEKLY: 'Weekly',
		MONTHLY: 'Monthly',
		SUBSCRIPTION_SUBMITTED: 'Subscription submitted.'
	},
	SETTINGS: {
		TITLE: 'Settings',
		LANGUAGE: 'Language',
		THEME: 'Theme',
		THEME_LIGHT: 'Light',
		THEME_LIGHT_CONTRAST_BIGGER: 'Light Contrast Bigger',
		THEME_DARK: 'Dark',
		THEME_DARK_CONTRAST_BIGGER: 'Dark Contrast Bigger',
		PAT: {
			TITLE: 'Personal Access Token',
			CREATE: 'Generate New Token',
			DESCRIPTION: 'Tokens you have generated that can be used to access the Watchmen API.',
			DELETE_CONFIRM: 'Are you sure to delete token? Please note that deletion cannot be recovered.',
			INPUT_PLACEHOLDER: 'A note for identify purpose of token',
			NOTE_REQUIRED: 'Note is required for a token.'
		}
	},
	PARAM: {
		FROM: 'From',
		FROM_TOPIC: 'Topic',
		FROM_CONSTANT: 'Constant',
		FROM_COMPUTED: 'Compute',
		FROM_INDICATOR: 'Refer',
		FROM_BUCKET: 'Bucket',
		FROM_TIME_FRAME: 'Time Frame'
	},
	FACTOR: {
		SEQUENCE: 'Sequence',
		NUMBER: 'Numeric',
		UNSIGNED: 'Unsigned Numeric',
		TEXT: 'Text',
		ADDRESS: 'Address',
		CONTINENT: 'Continent',
		REGION: 'Region',
		COUNTRY: 'Country',
		PROVINCE: 'State/Province',
		CITY: 'City',
		DISTRICT: 'District',
		ROAD: 'Road',
		COMMUNITY: 'Community',
		FLOOR: 'Floor',
		RESIDENCE_TYPE: 'Residence Type',
		RESIDENTIAL_AREA: 'Residential Area',
		EMAIL: 'Email',
		PHONE: 'Phone',
		MOBILE: 'Mobile',
		FAX: 'Fax',
		DATETIME: 'DateTime',
		FULL_DATETIME: 'Full DateTime',
		DATE: 'Date',
		TIME: 'Time',
		YEAR: 'Year',
		HALF_YEAR: '1st/2nd Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: '1st/2nd Half Month',
		TEN_DAYS: '1st/2nd/3rd Ten Days',
		WEEK_OF_YEAR: 'Week of Year',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: '1st/2nd Half Week',
		DAY_OF_MONTH: 'Day of Month',
		DAY_OF_WEEK: 'Day of Week',
		DAY_KIND: 'Workday/Weekday/Holiday',
		HOUR: 'Hour',
		HOUR_KIND: 'Work Time/Off Hours/Sleep Time',
		MINUTE: 'Minute',
		SECOND: 'Second',
		MILLISECOND: 'Millisecond',
		AM_PM: 'AM/PM',
		GENDER: 'Gender',
		OCCUPATION: 'Occupation',
		DATE_OF_BIRTH: 'Date of Birth',
		AGE: 'Age',
		ID_NO: 'ID No.',
		RELIGION: 'Religion',
		NATIONALITY: 'Nationality',
		BIZ_TRADE: 'Business Trade',
		BIZ_SCALE: 'Business Scale',
		BOOLEAN: 'Boolean',
		ENUM: 'Enumeration',
		OBJECT: 'Object',
		ARRAY: 'Array'
	},
	PARAMETER: {
		EXPRESSION: 'Expression',
		COMPUTE_TYPE: {
			NONE: 'As is',
			ADD: 'Add',
			SUBTRACT: 'Subtract',
			MULTIPLY: 'Multiply',
			DIVIDE: 'Divide',
			MODULUS: 'Modulus',
			YEAR_OF: 'Year of',
			HALF_YEAR_OF: 'Half Year of',
			QUARTER_OF: 'Quarter of',
			MONTH_OF: 'Month of',
			WEEK_OF_YEAR: 'Week of Year',
			WEEK_OF_MONTH: 'Week of Month',
			DAY_OF_MONTH: 'Day of Month',
			DAY_OF_WEEK: 'Day of Week',
			CASE_THEN: 'Case Then',
			ROUND: 'Round',
			ROUND_DIGITS: 'Digits',
			FLOOR: 'Floor',
			CEIL: 'Ceil',
			ABS: 'Abs',
			MAX: 'Max of',
			MIN: 'Min of',
			INTERPOLATE: 'Linear Interpolate',
			INTERPOLATE_MIN_VALUE: 'Bottom Value',
			INTERPOLATE_MIN_VALUE_TO: 'Bottom Value Map To',
			INTERPOLATE_MAX_VALUE: 'Top Value',
			INTERPOLATE_MAX_VALUE_TO: 'Top Value Map To'
		},
		EXPRESSION_OPERATOR: {
			EMPTY: 'Is Empty',
			NOT_EMPTY: 'Has Value',
			EQUALS: 'Equals',
			NOT_EQUALS: 'Not Equals',
			LESS: 'Less than',
			LESS_EQUALS: 'Less than or Equals',
			MORE: 'Greater than',
			MORE_EQUALS: 'Greater than or Equals',
			IN: 'In',
			NOT_IN: 'Not In'
		}
	},
	JOINT: {
		AND: 'And',
		OR: 'OR'
	},
	JOIN: {
		INNER: 'Exactly Match',
		LEFT: 'Left Side Prioritized',
		RIGHT: 'Right Side Prioritized'
	},
	FUNNEL: {
		COLUMN: 'Column - Type',
		RANGE: 'Range?',
		ENABLED: 'Enable?',
		NUMERIC: 'Numeric',
		DATE: 'Date',
		YEAR: 'Year',
		HALF_YEAR: 'Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: 'Half Month',
		TEN_DAYS: 'Ten Days',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: 'Half Week',
		DAY_KIND: 'Day Kind',
		DAY_OF_WEEK: 'Day of Week',
		HOUR: 'Hour',
		HOUR_KIND: 'Hour Kind',
		AM_PM: 'AM/PM',
		ENUM: 'Enumeration'
	},
	PAGINATION: {
		TOTAL: '',
		ROWS: 'Row(s) Total',
		OF_PAGES: 'of',
		PAGES: 'Pages'
	},
	CHART: {
		SETTINGS_HEADER_LABEL: 'Report Settings',
		SECTION_TITLE_BASIC_STYLE: 'Basic Style',
		SECTION_TITLE_DIMENSIONS: 'Dimensions',
		SECTION_TITLE_INDICATORS: 'Indicators',
		SECTION_TITLE_BASIC: 'Basic',
		SECTION_TITLE_PALETTE_SIZE: 'Palette Size',
		SECTION_TITLE_COLOR: 'Color Rendering',
		SECTION_TITLE_BORDER: 'Border Rendering',
		SECTION_TITLE_FONT: 'Font',
		SECTION_TITLE_POSITION: 'Position',
		SECTION_TITLE_PADDING: 'Padding',
		SECTION_TITLE_GAP_AND_PADDING: 'Gap & Padding',
		SECTION_TITLE_VALUE_FORMAT: 'Value Format',
		SECTION_TITLE_FUNNEL_DEFINITION: 'Funnel Definition',
		DIMENSIONS: 'Dimensions',
		INDICATORS: 'Indicators',
		FILTERS: 'Filters',
		NO_FUNNEL_DETECTED: 'No available funnel detected.',
		FUNNEL_DESCRIPTION: 'Available funnels are auto detected, enable them to adjust report data dynamically here and in dashboard.',
		FUNNEL_INSTANCE_DESCRIPTION: 'Open palette to define funnel values.',
		DESCRIPTION: 'Description',
		WIDTH: 'Width',
		HEIGHT: 'Height',
		PIXEL: 'PX',
		PERCENTAGE: '%',
		DEGREE: 'DEG',
		LINE_COLOR: 'Line Color',
		LINE_STYLE: 'Line Style',
		FONT_FAMILY: 'Font',
		FONT_COLOR: 'Font Color',
		FONT_SIZE: 'Font Size',
		FONT_STYLE: 'Font Style',
		FONT_STYLE_NORMAL: 'Normal',
		FONT_STYLE_ITALIC: 'Italic',
		FONT_WEIGHT: 'Font Weight',
		FONT_WEIGHT_100: '100',
		FONT_WEIGHT_200: '200',
		FONT_WEIGHT_300: '300',
		FONT_WEIGHT_400: '400',
		FONT_WEIGHT_500: '500',
		FONT_WEIGHT_600: '600',
		FONT_WEIGHT_700: '700',
		FONT_WEIGHT_800: '800',
		FONT_WEIGHT_900: '900',
		BACKGROUND_COLOR: 'Background Color',
		BORDER_STYLE: 'Border Style',
		BORDER_STYLE_NONE: 'None',
		BORDER_STYLE_SOLID: 'Solid',
		BORDER_STYLE_DASHED: 'Dashed',
		BORDER_STYLE_DOTTED: 'Dotted',
		BORDER_COLOR: 'Border Color',
		BORDER_WIDTH: 'Border Width',
		BORDER_RADIUS: 'Border Radius',
		POSITION: 'Position',
		POSITION_ON_OUTSIDE_OF_PIE: 'Outside Position',
		POSITION_TOP: 'Top',
		POSITION_RIGHT: 'Right',
		POSITION_LEFT: 'Left',
		POSITION_BOTTOM: 'Bottom',
		HORIZONTAL_ALIGNMENT: 'Alignment',
		HORIZONTAL_ALIGNMENT_AUTO: 'Auto',
		HORIZONTAL_ALIGNMENT_LEFT: 'Left',
		HORIZONTAL_ALIGNMENT_CENTER: 'Center',
		HORIZONTAL_ALIGNMENT_RIGHT: 'Right',
		VERTICAL_ALIGNMENT: 'Vertical Align',
		VERTICAL_ALIGNMENT_AUTO: 'Auto',
		VERTICAL_ALIGNMENT_TOP: 'Top',
		VERTICAL_ALIGNMENT_MIDDLE: 'Middle',
		VERTICAL_ALIGNMENT_BOTTOM: 'Bottom',
		TITLE_TEXT_ITEM_GAP: 'Gap of Titles',
		PADDING: 'Padding',
		SHOW: 'Show',
		SERIES_TEXT_SHOW: 'Show Series Text',
		SERIES_TEXT_POSITION: 'Series Text - Position',
		SERIES_TEXT_FONT: 'Series Text - Font',
		SERIES_TEXT_COLOR: 'Series Text - Color Rendering',
		SERIES_TEXT_BORDER: 'Series Text - Border Rendering',
		SERIES_TEXT_GAP_AND_PADDING: 'Series Text - Gap & Padding',
		LEGEND_ORIENT: 'Orient',
		LEGEND_ORIENT_HORIZONTAL: 'Horizontal',
		LEGEND_ORIENT_VERTICAL: 'Vertical',
		GRID_CONTAIN_LABEL: 'Contain Label',
		AXIS_TYPE: 'Axis Type',
		AXIS_TYPE_CATEGORY: 'Category',
		AXIS_TYPE_VALUE: 'Value',
		AXIS_TYPE_TIME: 'Time',
		AXIS_AUTO_MIN: 'Min Auto',
		AXIS_MIN: 'Min',
		AXIS_AUTO_MAX: 'Max Auto',
		AXIS_MAX: 'Max',
		AXIS_NAME_LOCATION: 'Location',
		AXIS_NAME_LOCATION_START: 'Start',
		AXIS_NAME_LOCATION_CENTER: 'Center',
		AXIS_NAME_LOCATION_END: 'End',
		AXIS_NAME_GAP: 'Gap',
		AXIS_NAME_ROTATE: 'Rotate',
		AXIS_LABEL_INSIDE: 'Inside',
		LABEL_GAP: 'Gap',
		LABEL_ROTATE: 'Rotate',
		LABEL_FORMAT_USING_GROUP: 'Using Group',
		LABEL_FORMAT_USING_PERCENTAGE: 'Using %',
		LABEL_VALUE_AS_PERCENTAGE: 'Value as %',
		LABEL_FRACTION_DIGITS: 'Fraction Digits',
		LABEL_POSITION_TOP: 'Top',
		LABEL_POSITION_LEFT: 'Left',
		LABEL_POSITION_RIGHT: 'Right',
		LABEL_POSITION_BOTTOM: 'Bottom',
		LABEL_POSITION_INSIDE: 'Inside',
		LABEL_POSITION_INSIDE_LEFT: 'Inside Left',
		LABEL_POSITION_INSIDE_RIGHT: 'Inside Right',
		LABEL_POSITION_INSIDE_TOP: 'Inside Top',
		LABEL_POSITION_INSIDE_BOTTOM: 'Inside Bottom',
		LABEL_POSITION_INSIDE_TOP_LEFT: 'Inside Top Left',
		LABEL_POSITION_INSIDE_BOTTOM_LEFT: 'Inside Bottom Left',
		LABEL_POSITION_INSIDE_TOP_RIGHT: 'Inside Top Right',
		LABEL_POSITION_INSIDE_BOTTOM_RIGHT: 'Inside Bottom Right',
		LABEL_POSITION_OUTSIDE: 'Outside',
		LABEL_POSITION_CENTER: 'Center',
		DECAL: 'Decal',
		ROAM: 'Roam',
		TREE_LAYOUT: 'Layout',
		TREE_LAYOUT_ORTHOGONAL: 'Orthogonal',
		TREE_LAYOUT_RADIAL: 'Radial',
		TREE_ORIENT: 'Orient',
		TREE_ORIENT_LEFT_RIGHT: 'Left to Right',
		TREE_ORIENT_RIGHT_LEFT: 'Right to Left',
		TREE_ORIENT_TOP_BOTTOM: 'Top to Bottom',
		TREE_ORIENT_BOTTOM_TOP: 'Bottom to Top',
		PIE_CENTER_X: 'Center X',
		PIE_CENTER_Y: 'Center Y',
		PIE_INSIDE_RADIUS: 'Inside Radius',
		PIE_OUTSIDE_RADIUS: 'Outside Radius',
		PIE_ROSE_TYPE: 'Rose Type',
		PIE_ROSE_TYPE_NONE: 'None',
		PIE_ROSE_TYPE_RADIUS: 'By Radius',
		PIE_ROSE_TYPE_AREA: 'By Area',
		PIE_SHOW_PERCENTAGE: 'Show %',
		PIE_LABEL_ALIGN_TO_NONE: 'None',
		PIE_LABEL_ALIGN_TO_LABEL_LINE: 'Label Line',
		PIE_LABEL_ALIGN_TO_EDGE: 'Edge',
		MAP_REGION: 'Region',
		MAP_REGION_CHINA_L1: 'China (Province)',
		MAP_REGION_CYPRUS_L1: 'Cyprus',
		MAP_REGION_JAPAN_L1: 'Japan (Prefecture)',
		MAP_REGION_SINGAPORE_L1: 'Singapore',
		MAP_REGION_USA_L1: 'United States (State)',
		TRUNCATION_TYPE: 'Type',
		TRUNCATION_NONE: 'None',
		TRUNCATION_TOP: 'Top',
		TRUNCATION_BOTTOM: 'Bottom',
		TRUNCATION_COUNT: 'Count',
		BAR_TRANSFORM_AXIS: 'Transform Axis',
		LINE_SMOOTH: 'Smooth',
		TOOLBOX_ORIENT: 'Orient',
		TOOLBOX_ORIENT_HORIZONTAL: 'Horizontal',
		TOOLBOX_ORIENT_VERTICAL: 'Vertical',
		SECTION_TITLE_COUNT_CHART: 'Count Style',
		SECTION_TITLE_BAR_CHART: 'Bar/Line style',
		SECTION_TITLE_PIE_CHART: 'Pie Style',
		SECTION_TITLE_TREE_CHART: 'Tree Style',
		SECTION_TITLE_TREEMAP_CHART: 'Treemap Style',
		SECTION_TITLE_MAP_CHART: 'Map Style',
		SECTION_TITLE_ECHART_TITLE: 'Title',
		SECTION_TITLE_ECHART_SUBTITLE: 'Subtitle',
		SECTION_TITLE_ECHART_LEGEND: 'Legend',
		SECTION_TITLE_ECHART_GRID: 'Grid',
		SECTION_TITLE_ECHART_XAXIS: 'X-Axis',
		SECTION_TITLE_ECHART_XAXIS_RANGE: 'X-Axis Range',
		SECTION_TITLE_ECHART_XAXIS_NAME: 'X-Axis Name',
		SECTION_TITLE_ECHART_XAXIS_NAME_FONT: 'X-Axis Name - Font',
		SECTION_TITLE_ECHART_XAXIS_NAME_COLOR: 'X-Axis Name - Color Rendering',
		SECTION_TITLE_ECHART_XAXIS_NAME_BORDER: 'X-Axis Name - Border Rendering',
		SECTION_TITLE_ECHART_XAXIS_NAME_GAP_AND_PADDING: 'X-Axis Name - Gap & Padding',
		SECTION_TITLE_ECHART_XAXIS_LABEL: 'X-Axis Label',
		SECTION_TITLE_ECHART_XAXIS_LABEL_FONT: 'X-Axis Label - Font',
		SECTION_TITLE_ECHART_XAXIS_LABEL_COLOR: 'X-Axis Label - Color Rendering',
		SECTION_TITLE_ECHART_XAXIS_LABEL_BORDER: 'X-Axis Label - Border Rendering',
		SECTION_TITLE_ECHART_XAXIS_LABEL_GAP_AND_PADDING: 'X-Axis Label - Gap & Padding',
		SECTION_TITLE_ECHART_XAXIS_LINE: 'X-Axis Split Line',
		SECTION_TITLE_ECHART_XAXIS_MINOR_LINE: 'X-Axis Minor Split Line',
		SECTION_TITLE_ECHART_YAXIS: 'Y-Axis',
		SECTION_TITLE_ECHART_YAXIS_RANGE: 'Y-Axis Range',
		SECTION_TITLE_ECHART_YAXIS_NAME: 'Y-Axis Name',
		SECTION_TITLE_ECHART_YAXIS_NAME_FONT: 'Y-Axis Name - Font',
		SECTION_TITLE_ECHART_YAXIS_NAME_COLOR: 'Y-Axis Name - Color Rendering',
		SECTION_TITLE_ECHART_YAXIS_NAME_BORDER: 'Y-Axis Name - Border Rendering',
		SECTION_TITLE_ECHART_YAXIS_NAME_GAP_AND_PADDING: 'Y-Axis Name - Gap & Padding',
		SECTION_TITLE_ECHART_YAXIS_LABEL: 'Y-Axis Label',
		SECTION_TITLE_ECHART_YAXIS_LABEL_FONT: 'Y-Axis Label - Font',
		SECTION_TITLE_ECHART_YAXIS_LABEL_COLOR: 'Y-Axis Label - Color Rendering',
		SECTION_TITLE_ECHART_YAXIS_LABEL_BORDER: 'Y-Axis Label - Border Rendering',
		SECTION_TITLE_ECHART_YAXIS_LABEL_GAP_AND_PADDING: 'Y-Axis Label - Gap & Padding',
		SECTION_TITLE_ECHART_YAXIS_LINE: 'Y-Axis Split Line',
		SECTION_TITLE_ECHART_YAXIS_MINOR_LINE: 'Y-Axis Minor Split Line',
		SECTION_TITLE_ECHART_SCRIPT: 'Script',
		SECTION_TITLE_ECHART_SCRIPT_VARS_DEF: 'Variables Defs',
		SECTION_TITLE_ECHART_SCRIPT_VARS: 'Variables',
		SECTION_TITLE_TRUNCATION: 'Data Truncation',
		SECTION_TITLE_TOOLBOX: 'Toolbox',
		NONAME_COLUMN: 'Noname Column',
		UNKNOWN_COLUMN_NAME: 'Unknown Column',
		CAN_NOT_DELETE_DIMENSION: 'Cannot delete this because of reach minimum dimension(s).',
		CAN_NOT_ADD_DIMENSION: 'Cannot add more because of reach maximum dimension(s).',
		CAN_NOT_DELETE_INDICATOR: 'Cannot delete this because of reach minimum indicator(s).',
		CAN_NOT_ADD_INDICATOR: 'Cannot add more because of reach maximum indicator(s).',
		ADD_DIMENSION: 'Add Dimension',
		ADD_INDICATOR: 'Add Indicator',
		PLEASE_SELECT_DIMENSION: 'Please select...',
		PLEASE_SELECT_INDICATOR: 'Please select...',
		PLEASE_SELECT_FUNNEL_VALUE: 'Please select...',
		NO_ENUM_FUNNEL_VALUE: 'No value selected',
		NO_SCRIPT_VARS: 'No script variables defined.',
		ARITHMETIC_NONE: 'As Is',
		ARITHMETIC_SUMMARY: 'Sum',
		ARITHMETIC_AVERAGE: 'Avg',
		ARITHMETIC_COUNT: 'Count',
		ARITHMETIC_DISTINCT_COUNT: 'Distinct Count',
		ARITHMETIC_MAX: 'Max',
		ARITHMETIC_MIN: 'Min',
		TYPE: 'Type',
		TYPES: {
			COUNT: 'Single Value',
			BAR: 'Bar',
			LINE: 'Line',
			SCATTER: 'Scatter',
			PIE: 'Pie',
			DOUGHNUT: 'Doughnut',
			NIGHTINGALE: 'Nightingale',
			SUNBURST: 'Sunburst',
			TREE: 'Tree',
			TREEMAP: 'Treemap',
			MAP: 'Map',
			CUSTOMIZED: 'Customized'
		},
		COUNT: {
			FORMAT_USING_GROUP: 'Using Group'
		},
		ECHART: {
			TEXT: 'Text'
		},
		DEFINITION_BROKEN: 'Definition not complete, cannot render chart for now.'
	},
	MEASURE_METHOD: {
		CONTINENT: 'Continent',
		REGION: 'Region',
		COUNTRY: 'Country',
		PROVINCE: 'Province',
		CITY: 'City',
		DISTRICT: 'District',
		FLOOR: 'Floor',
		RESIDENCE_TYPE: 'Residence Type',
		RESIDENTIAL_AREA: 'Residence Area',
		YEAR: 'Year',
		HALF_YEAR: '1st/2nd Half Year',
		QUARTER: 'Quarter',
		MONTH: 'Month',
		HALF_MONTH: '1st/2nd Half Month',
		TEN_DAYS: '1st/2nd/3rd Ten Days',
		WEEK_OF_YEAR: 'Week of Year',
		WEEK_OF_MONTH: 'Week of Month',
		HALF_WEEK: '1st/2nd Half Week',
		DAY_OF_MONTH: 'Day of Month',
		DAY_OF_WEEK: 'Day of Week',
		DAY_KIND: 'Workday/Weekday/Holiday',
		HOUR: 'Hour',
		HOUR_KIND: 'Work Time/Off Hours/Sleep Time',
		AM_PM: 'AM/PM',
		GENDER: 'Gender',
		OCCUPATION: 'Occupation',
		AGE: 'Age',
		RELIGION: 'Religion',
		NATIONALITY: 'Nationality',
		BIZ_TRADE: 'Business Trade',
		BIZ_SCALE: 'Business Scale',
		BOOLEAN: 'Boolean',
		ENUM: 'Enumeration'
	},
	RELEVANT_INDICATOR_TYPE: {
		SAME: 'Same',
		HIGH_CORRELATED: 'High Correlated',
		WEAK_CORRELATED: 'Weak Correlated',
		THIS_CAUSES_RELEVANT: 'Causes Relevant',
		RELEVANT_CAUSES_THIS: 'Caused by Relevant'
	},
	SHARE: {
		NOTHING: 'No content can be found, contact administrator for more information.'
	},
	CALENDAR: {
		JAN: 'January',
		FEB: 'February',
		MAR: 'March',
		APR: 'April',
		MAY: 'May',
		JUN: 'June',
		JUL: 'July',
		AUG: 'August',
		SEP: 'September',
		OCT: 'October',
		NOV: 'November',
		DEC: 'December',
		WEEK: 'Week',
		WEEK_0: '0th Week (Incomplete week)',
		WEEK_1: '1st Week',
		WEEK_2: '2nd Week',
		WEEK_3: '3rd Week',
		WEEK_4: '4th Week',
		WEEK_5: '5th Week (Incomplete week)',
		HALF_YEAR_1ST: '1st Half Year',
		HALF_YEAR_2ND: '2nd Half Year',
		QUARTER_1ST: '1st Quarter',
		QUARTER_2ND: '2nd Quarter',
		QUARTER_3RD: '3rd Quarter',
		QUARTER_4TH: '4th Quarter',
		HALF_MONTH_1ST: '1st Half Month',
		HALF_MONTH_2ND: '2nd Half Month',
		TEN_DAYS_1ST: '1st Ten Days Of Month',
		TEN_DAYS_2ND: '2nd Ten Days Of Month',
		TEN_DAYS_3RD: '3rd Ten Days Of Month',
		HALF_WEEK_1ST: '1st Half Week',
		HALF_WEEK_2ND: '2nd Half Week',
		SUNDAY: 'Sunday',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
		WORKDAY: 'Workday',
		WEEKEND: 'Weekend',
		HOLIDAY: 'Holiday',
		WORK_TIME: 'Work Time',
		OFF_HOURS: 'Off Hours',
		SLEEPING_TIME: 'Sleeping Time',
		AM: 'AM',
		PM: 'PM'
	},
	CONSANGUINITY: {
		OBJECTIVE_TARGET_BLOCK_LABEL: 'Targets',
		OBJECTIVE_FACTOR_BLOCK_LABEL: 'Measure Indicators',
		INDICATOR_BLOCK_LABEL: 'Indicators',
		SUBJECT_BLOCK_LABEL: 'Subjects',
		REPORT_BLOCK_LABEL: 'Reports',
		TOPIC_BLOCK_LABEL: 'Topics',
		BUCKET_BLOCK_LABEL: 'Buckets',
		ENUM_BLOCK_LABEL: 'Enums',
		NO_OBJECTIVE_TARGET_FOUND: 'No objective target found.',
		NONAME_OBJECTIVE_TARGET: 'Noname Objective Target',
		NONAME_OBJECTIVE_FACTOR: 'Noname Measure Indicator',
		NONAME_INDICATOR: 'Noname Indicator',
		NONAME_SUBJECT: 'Noname Subject',
		NONAME_SUBJECT_COLUMN: 'Noname Column',
		NONAME_REPORT: 'Noname Report',
		NONAME_TOPIC: 'Noname Topic',
		NONAME_TOPIC_FACTOR: 'Noname Topic Factor',
		NONAME_BUCKET: 'Noname Bucket',
		NONAME_ENUM: 'Noname Enum'
	}
};
