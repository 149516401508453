export enum Router {
	LOGIN = '/login',
	MOCK_SAML2_LOGIN = '/saml/login',
	SAML2_CALLBACK = '/saml/callback',

	ADMIN = '/admin',
	ADMIN_ALL = '/admin/*',
	ADMIN_HOME = '/admin/home',
	ADMIN_TOPICS = '/admin/topics',
	ADMIN_ENUMS = '/admin/enums',
	ADMIN_REPORTS = '/admin/reports',
	ADMIN_SPACES = '/admin/space',
	ADMIN_PIPELINES = '/admin/pipeline',
	ADMIN_PIPELINES_ALL = '/admin/pipeline/*',
	ADMIN_PIPELINE = '/admin/pipeline/:pipelineId',
	ADMIN_PIPELINE_CATALOG = '/admin/pipeline/catalog',
	ADMIN_USER_GROUPS = '/admin/user-groups',
	ADMIN_USERS = '/admin/users',
	ADMIN_TENANTS = '/admin/tenants',
	ADMIN_DATA_SOURCES = '/admin/data-sources',
	ADMIN_EXTERNAL_WRITERS = '/admin/external-writers',
	ADMIN_PLUGINS = '/admin/plugins',
	ADMIN_MONITOR_LOGS = '/admin/monitor_logs',
	ADMIN_SIMULATOR = '/admin/simulator',
	ADMIN_SETTINGS = '/admin/settings',
	ADMIN_TOOLBOX = '/admin/toolbox',
	ADMIN_TOOLBOX_ALL = '/admin/toolbox/*',
	ADMIN_TOOLBOX_TOPIC_SNAPSHOT = '/admin/toolbox/topic-snapshot',
	ADMIN_TOOLBOX_PIPELINE_TRIGGER = '/admin/toolbox/pipeline-trigger',

	CONSOLE = '/console',
	CONSOLE_ALL = '/console/*',
	CONSOLE_HOME = '/console/home',
	CONSOLE_DASHBOARD = '/console/dashboard',
	CONSOLE_DASHBOARD_ALL = '/console/dashboard/*',
	CONSOLE_DASHBOARD_EDIT = '/console/dashboard/:dashboardId',
	CONSOLE_CONNECTED_SPACE = '/console/space/connected/:connectId',
	CONSOLE_CONNECTED_SPACE_ALL = '/console/space/connected/:connectId/*',
	CONSOLE_CONNECTED_SPACE_CATALOG = '/console/space/connected/:connectId/catalog',
	CONSOLE_CONNECTED_SPACE_SUBJECT = '/console/space/connected/:connectId/subject/:subjectId',
	CONSOLE_CONNECTED_SPACE_SUBJECT_ALL = '/console/space/connected/:connectId/subject/:subjectId/*',
	CONSOLE_CONNECTED_SPACE_SUBJECT_DEF = '/console/space/connected/:connectId/subject/:subjectId/def',
	CONSOLE_CONNECTED_SPACE_SUBJECT_DATA = '/console/space/connected/:connectId/subject/:subjectId/data',
	CONSOLE_CONNECTED_SPACE_SUBJECT_REPORT = '/console/space/connected/:connectId/subject/:subjectId/report',
	CONSOLE_CONNECTED_SPACE_SUBJECT_REPORT_EDIT = '/console/space/connected/:connectId/subject/:subjectId/report/:reportId',
	CONSOLE_DERIVED_OBJECTIVE = '/console/objective/derived/:derivedObjectiveId',
	CONSOLE_DERIVED_OBJECTIVE_ALL = '/console/objective/derived/:derivedObjectiveId/*',
	CONSOLE_SETTINGS = '/console/settings',

	DQC = '/dqc',
	DQC_ALL = '/dqc/*',
	DQC_HOME = '/dqc/home',
	DQC_CONSANGUINITY = '/dqc/consanguinity',
	DQC_CATALOG = '/dqc/catalog',
	DQC_RULES = '/dqc/rules',
	DQC_STATISTICS = '/dqc/statistics',
	DQC_END_USER = '/dqc/end-user',
	DQC_SETTINGS = '/dqc/settings',

	IDW = '/idw',
	IDW_ALL = '/idw/*',
	IDW_BUCKETS = '/idw/bucket',
	IDW_INDICATOR = '/idw/indicator',
	IDW_INDICATOR_ALL = '/idw/indicator/*',
	IDW_INDICATOR_EDIT = '/idw/indicator/edit',
	IDW_OBJECTIVE = '/idw/objective',
	IDW_OBJECTIVE_ALL = '/idw/objective/*',
	IDW_OBJECTIVE_EDIT = '/idw/objective/edit',
	IDW_CONVERGENCE = '/idw/convergence',
	IDW_CONVERGENCE_ALL = '/idw/convergence/*',
	IDW_CONVERGENCE_EDIT = '/idw/convergence/edit',
	IDW_SETTINGS = '/idw/settings',

	SHARE = '/share',
	SHARE_ALL = '/share/*',
	SHARE_DASHBOARD = '/share/dashboard/:dashboardId/:token',
	SHARE_DERIVED_OBJECTIVE = '/share/objective/derived/:derivedObjectiveId/:token'
}
